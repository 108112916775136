import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialCapital } from 'redux/modules/banking/actions';
import { getInitialCapital } from 'redux/modules/banking/selectors';

import {
  Form,
  Button,
  Input
} from 'antd';

const layout = {
  'labelCol': {
    'span': 5

  },
  'wrapperCol': {
    'span': 5

  }
};
const tailLayout = {
  // wrapperCol: {
  //   offset: 5,
  //   span: 3,
  // },
};

const Account = () => {
  const dispatch = useDispatch();
  const initialCapital = useSelector(getInitialCapital);

  const onFinish = values => {
    dispatch(setInitialCapital(values.initialCapital));
  };

  return (
    <Form
      {...layout}
      layout="vertical"
      name="basic"
      hideRequiredMark
      initialValues={{
        initialCapital
      }}
      onFinish={onFinish}>
      <Form.Item label="Initial Capital" name="initialCapital">
        <Input/>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>

  );
};

export default Account;

