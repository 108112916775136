import types from './types';

export const setInitialCapital = initialCapital => ({
  'type': types.SET_INITIAL_CAPITAL_REQUEST,
  initialCapital
});

export const syncFund = fund => ({
  'type': types.SYNC_FUND,
  fund
});

export const setAccountBalance = accountBalance => ({
  'type': types.SET_ACCOUNT_BALANCE_REQUEST,
  accountBalance
});

export const addTransaction = transaction => ({
  'type': types.ADD_TRANSACTION_REQUEST,
  transaction
});

export const getTransactions = () => ({
  'type': types.GET_TRANSACTIONS_REQUEST
});

export const getTransactionsSuccess = (transactions) => ({
  'type': types.GET_TRANSACTIONS_REQUEST,
  transactions
});
