import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { auth, signInWithGoogle, signOut as _signOut } from '../service/firebase';
import createUser from 'utils/createUser';
import { loginSuccess } from 'redux/modules/user/actions';

const defaultSession = { 'loading': true,
 'isApproved': false,
isClient: false};

// Provider hook that creates auth object and handles state
function useProviderAuth() {
 const dispatch = useDispatch();
 const [session, setSession] = useState(defaultSession);

 // Wrap any Firebase methods we want to use making sure ...
 // ... to save the user to state.
 const signIn = () => {
  signInWithGoogle();
 };

 const signOut = () => _signOut()
  .then(() => {
   setSession({ 'isSignedIn': false,
    'user': null });
  });
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

 useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(_user => {
   if (_user) {
    _user.getIdTokenResult().then((idToken) => {
     const isSignedIn = !!_user;
     const user = isSignedIn ? createUser(_user) : {};

     console.log('idToken.claims :>> ', user, idToken.claims);

     if (idToken.claims.isApproved === true) {
      setSession({ isSignedIn,
       user,
       'loading': false,
       'isApproved': true,
       'isClient': idToken.claims.isClient
      });
      dispatch(loginSuccess(user));
     } else {
      setSession({ isSignedIn,
       user,
       'loading': false,
       'isApproved': false });
     }
    });
   } else {
    setSession({ 'isSignedIn': false,
     'loading': false,
     'isApproved': false });
   }
  });

  // Cleanup subscription on unmount
  return () => unsubscribe();
 }, [dispatch]);

 // Return the user object and auth methods
 return {
  session,
  signIn,
  signOut
 };
}

export default useProviderAuth;
