import typesGenerator from 'utils/typesGenerator';

function generateTypes(type) {
  return typesGenerator('admin')(type);
}

const types = {
  ...generateTypes('ADD_STOCK'),
  ...generateTypes('GET_STOCKS'),
  ...generateTypes('DELETE_STOCK')
};

export default types;
