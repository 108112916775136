import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getInitialCapital } from 'redux/modules/banking/selectors';
import { getStrategies } from 'redux/modules/trading/selectors';
import { addTrade, getStrategies as getStrategiesAction, updateTrade } from 'redux/modules/trading/actions';
import { getStocks as getStocksAction } from 'redux/modules/settings/actions';

import { Form, Row, Col, Input, Button, Select, Tag, Switch, Card } from 'antd';
import { getClosedPosition } from 'utils/brokerage';
import { getStocks } from 'redux/modules/settings/selectors';

import styles from '../styles.less';

const { Option } = Select;

const basicRules = [{ 'required': true,
  'message': 'Please input a value' }];

const TradeForm = ({ trade = {}, handleSave }) => {
  const [form] = Form.useForm();
  const [riskPercentage, setRiskPercentage] = useState(trade.riskPercentage || 0);
  const [risk, setRisk] = useState(trade.risk || 0);

  const [action, setAction] = useState(trade.action || 'SHORT');
  const [riskReward, setRiskReward] = useState(trade.riskReward || 0);
  const initialCapital = useSelector(getInitialCapital);
  const strategies = useSelector(getStrategies);
  const stocks = useSelector(getStocks);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStrategiesAction());
    dispatch(getStocksAction());
  }, [dispatch]);

  const getBreakeven = () => {
    const price = parseFloat(form.getFieldValue('price'));
    const stopLoss = parseFloat(form.getFieldValue('stopLoss'));
    const quantity = parseFloat(form.getFieldValue('quantity'));

    let { breakeven } = getClosedPosition(price, stopLoss, quantity);

    if (action === 'SHORT') {
      breakeven = price - breakeven;
    } else {
      breakeven = price + breakeven;
    }

    return breakeven.toFixed(2);
  };

  const handleStockChange = (name) => {
    const currentStock = stocks.find(obj => obj.name === name) || {};

    form.setFieldsValue({ 'quantity': currentStock.quantity });
    // console.log('stocks --',currentStock )
    // setQuantity(currentStock.quantity)
  };

  // if(stocks.length === 0 || strategies.length === 0) {
  //   return null;
  // }

  const handlePriceChange = () => {
    handleRisk();
    // setBreakeven(getBreakeven())
  };

  const onFinish = values => {
    // console.log('Received values of form: ', values);

    if (trade.id) {
      dispatch(updateTrade({
        ...trade,
        ...values,
        'id': trade.id,
        risk,
        riskPercentage,
        riskReward,
        'breakeven': getBreakeven(),
        action
      }));
    } else {
      dispatch(addTrade({
        ...values,
        // date:  moment().format('YYYYMMDD'),
        risk,
        riskPercentage,
        riskReward,
        'breakeven': getBreakeven(),
        action
      }));
    }

    form.resetFields();
    handleSave();
  };

  const handleRisk = () => {
    const price = form.getFieldValue('price') || 0;
    const stopLoss = form.getFieldValue('stopLoss') || 0;
    const quantity = form.getFieldValue('quantity') || 0;
    const target = form.getFieldValue('target') || 0;
    const { breakeven } = getClosedPosition(price, stopLoss, quantity);

    const risk = ((Math.abs(price - stopLoss) * quantity)) + breakeven;
    const riskPercentage = (risk / initialCapital) * 100;
    const reward = ((Math.abs(target - price) * quantity) / initialCapital) * 100;

    setRisk(risk.toFixed(2));
    setRiskReward((reward / riskPercentage).toFixed(2));
    setRiskPercentage(riskPercentage.toFixed(2));
  };
  const handleAction = (isChecked) => {
    if (isChecked) {
      setAction('LONG');
    } else {
      setAction('SHORT');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="advanced_search"
      onFinish={onFinish}
      hideRequiredMark
      className={styles.tradeForm}
      initialValues={{
        ...trade
      }}
      style={{
        'padding': '24px',
        // background: '#fff',
        'border': '1px solid #d9d9d9',
        'marginBottom': '20px'
        // width: '90%'
      }}>
      <Row gutter={24}>

        <Col>
          <Form.Item
            rules={basicRules}
            name="stock"
            label="Stock">
            <Select showSearch onChange={handleStockChange} style={{ 'width': '150px' }}>
              {stocks.map(item => (
                <Option key={item.name}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Action">
            <Switch onClick={handleAction} checkedChildren="LONG" unCheckedChildren="SHORT" defaultChecked={trade.action === 'LONG'} />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            rules={basicRules}
            name="quantity"
            label="Quantity">
            <Input autoComplete="off" onChange={handleRisk} />

          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            name="price"
            label="Entry">
            <Input autoComplete="off" onChange={handlePriceChange} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            name="stopLoss"
            label="Stop Loss">
            <Input autoComplete="off" onChange={handleRisk}/>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            name="target"
            label="Target">
            <Input autoComplete="off" onChange={handleRisk}/>

          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            label="Risk">
            <span style={{ 'fontWeight': '500',
              'color': 'red' }}>{risk}</span>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="% Risk">
            <span style={{ 'fontWeight': '500',
              'color': 'red' }}>{riskPercentage}%</span>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            style={{ 'alignItems': 'centre' }}
            label="Planned R:R">
            <Tag
              color="green" style={{ 'fontWeight': 'bold',
                'marginLeft': '20px' }}>{`1: ${riskReward}`}</Tag>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            name="strategy"
            label="Reason for Entry">
            <Select mode="multiple" style={{ 'width': '200px' }}>
              {strategies.map(item => (
                <Option key={item.name}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TradeForm;
