import React, { useState } from 'react';
import { Menu } from 'antd';
import styles from './settings.less';
import Pages from './SettingsView';

const Settings = () => {
  const [seletedMenuItem, selectMenuItem] = useState('stocks');

  return (
    <div className={styles.main}>
      <div className={styles.leftMenu}>
        <Menu
          selectedKeys={[seletedMenuItem]}
          onClick={({ key }) =>
            selectMenuItem(key)
          }>
          <Menu.Item key="stocks">Stocks</Menu.Item>
          <Menu.Item key="strategy">Strategy</Menu.Item>
          {/* <Menu.Item key="brokerage">Brokerage Settings</Menu.Item> */}
          <Menu.Item key="account">Account Settings</Menu.Item>

        </Menu>
      </div>
      <div className={styles.right}>
        <Pages name={seletedMenuItem} />
      </div>
    </div>);
};

export default Settings;

