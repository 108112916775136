import types from './types';

export const loginSuccess = user => ({
 'type': types.LOGIN_SUCCESS,
 user
});

// export const createAccessToken = accessToken => ({
//   type: CREATE_ACCESS_TOKEN,
//   accessToken,
// });

