import {
  all,
  fork,
  call,
  takeLatest,
  take,
  cancel,
  put,
} from "redux-saga/effects";

import dbService from "service/dbService";
import userTypes from "redux/modules/user/types";
import types from "./types";
import {
  syncTrades,
  getStrategiesSuccess,
  getTradingZonesSuccess,
  syncPendingOrdersSuccess,
  syncOrderSuccess,
  syncZoneWishListSuccess,
  updateTradingZone,
  getAllTradingZonesSuccess,
} from "./actions";
import objectToArray from "utils/objectToArray";
import { database, getUserId } from "../../../service/firebase";
import moment from "moment";
import { getAllTradingZones } from "./selectors";

const objectTransformer = ({ value }) => objectToArray(value);

const pendingOrderTransformer = ({ value }) => {
  const pendingOrders = objectToArray(value).filter(
    (order) => order.userId === getUserId()
  );
  const orders = pendingOrders.map((pendingOrder) => {
    const order = {
      ...pendingOrder.order,
      id: pendingOrder.id,
      status: "PENDING",
      stock: pendingOrder.order.tradingsymbol,
      action: pendingOrder.order.transaction_type,
    };

    return order;
  });

  console.log("pending orders---", orders);

  return orders;
};

function* syncTradesSaga() {
  const task = yield fork(dbService.sync, "trades", {
    successActionCreator: syncTrades,
    transform: objectTransformer,
  });
}

function* syncTradingZoneSaga() {
  const task = yield fork(dbService.sync, "trading_zones", {
    successActionCreator: getTradingZonesSuccess,
    transform: objectTransformer,
  });

  console.log("syncTradingZoneSaga");

  //  yield* getAllTradingZonesSaga();
}

function* syncZoneWishListSaga() {
  const task = yield fork(dbService.sync, "zone_wishlist", {
    successActionCreator: syncZoneWishListSuccess,
    transform: objectTransformer,
  });
}

function* syncPendingOrders() {
  const task = yield fork(dbService.sync, database.ref("pending_actions"), {
    successActionCreator: syncPendingOrdersSuccess,
    transform: pendingOrderTransformer,
  });
}

// function* syncOrderSaga() {
//  const task = yield fork(
//   dbService.sync,
//   'orders',
//   {
//    'successActionCreator': syncOrderSuccess,
//    'transform': objectTransformer
//   }
//  );
// }

function* syncOrderSaga() {
  const task = yield fork(dbService.sync, database.ref("orders"), {
    successActionCreator: syncOrderSuccess,
    transform: objectTransformer,
  });
}

function* addTradeSaga(action) {
  const { trade } = action;

  yield call(dbService.create, "trades", { ...trade });
}

function* updateTradeSaga(action) {
  const { trade } = action;

  yield call(dbService.patch, `trades/${trade.id}`, { ...trade });
}

function* deleteTradeSaga(action) {
  const { id } = action;

  yield call(dbService.delete, `trades/${id}`);
}

function* addStrategySaga(action) {
  const { strategy } = action;

  yield call(dbService.create, "strategies", { ...strategy });
  yield call(getStrategiesSaga);
}

function* deleteStrategySaga(action) {
  const { id } = action;

  yield call(dbService.delete, `strategies/${id}`);

  yield call(getStrategiesSaga);
}

function* getStrategiesSaga() {
  const strategies = yield call(dbService.read, "strategies");

  yield put(getStrategiesSuccess(objectToArray(strategies)));
}

function* addTradingZone(action) {
  const { tradingZone } = action;

  yield call(dbService.create, "trading_zones", {
    ...tradingZone,
    date: moment().format("YYYY-MM-DD hh:mm a"),
  });
}

function* updateTradingZoneSaga(action) {
  const { tradingZone } = action;

  yield call(dbService.patch, `trading_zones/${tradingZone.id}`, {
    ...tradingZone,
    date: moment().format("YYYY-MM-DD hh:mm a"),
  });

  // const order = {
  //     quantity: tradingZone.quantity,
  //     tradingsymbol: tradingZone.stock,
  //     transaction_type: tradingZone.action,
  //     price: tradingZone.price,
  //     tradingZoneId: tradingZone.id,
  //     breakeven: tradingZone.breakeven,
  //     breakevenTrigger: tradingZone.breakevenTrigger,
  //     stopLoss: tradingZone.stopLoss,
  //     trailingStopLoss: tradingZone.trailingStopLoss,
  //     target: tradingZone.target,
  //     risk: tradingZone.risk,
  //     riskReward: tradingZone.riskReward,
  //     riskPercentage: tradingZone.riskPercentage,
  //     strategy: tradingZone.strategy,
  //     orderId: tradingZone.orderId,
  //     stopLossOrderId: tradingZone.stopLossOrderId,
  //     targetOrderId: tradingZone.targetOrderId,
  // }

  // if (tradingZone.orderStatus !== 'PENDING') {
  //   yield call(CloudApi.updateZoneOrder, order);
  // }
}

function* deleteTradingZoneSaga(action) {
  const { id } = action;

  yield call(dbService.delete, `trading_zones/${id}`);
}

function* deleteZoneWishListSaga(action) {
  const { id } = action;

  yield call(dbService.delete, `zone_wishlist/${id}`);
}

function* getTradingZonesSaga() {
  const tradingZones = yield call(dbService.read, "trading_zones");

  yield put(getTradingZonesSuccess(objectToArray(tradingZones)));
}

function* getAllTradingZonesSaga() {
  console.log("getAllTradingZonesSaga");
  const firstZone = yield call(
    dbService.read,
    database.ref("users/iOt7QxsZDQbU2cuDWtXc5U4md6x2/trading_zones"),
    "trading_zones"
  );

  console.log("firstZone", firstZone);

  const secondZone = yield call(
    dbService.read,
    database.ref("users/qyxPMfWY3gVDLjzVqLtyp3CxEo12/trading_zones"),
    "trading_zones"
  );

  const tradingZones = [
    ...objectToArray(firstZone, { userName: "Renjith" }),
    ...objectToArray(secondZone, { userName: "Sandheep" }),
  ];

  console.log("tradingZones", tradingZones);
  yield put(getAllTradingZonesSuccess(objectToArray(tradingZones)));
}

function* placeOrderSaga(action) {
  const { order, variety } = action;

  try {
    const currentDate = moment();
    const weekday = currentDate.weekday();

    const hour = currentDate.hour();
    const minute = currentDate.minutes();

    console.log("\n\nsaga order", order);

    console.log(
      "time",
      weekday,
      hour,
      minute,
      weekday === 0 ||
        weekday === 7 ||
        hour < 9 ||
        hour > 15 ||
        (hour === 9 && minute < 15)
    );

    if (
      weekday === 0 ||
      weekday === 7 ||
      hour < 9 ||
      hour > 15 ||
      (hour === 9 && minute < 15)
    ) {
      yield call(
        dbService.update,
        database.ref(`pending_actions/${order.tradingZoneId}`),
        {
          type: "PLACE_ORDER",
          userId: getUserId(),
          order,
          variety,
        }
      );

      yield call(dbService.patch, `trading_zones/${order.tradingZoneId}`, {
        orderStatus: "OPEN",
      });

      return;
    }

    yield call(dbService.create, database.ref("actions"), {
      type: "PLACE_ORDER",
      userId: getUserId(),
      order,
      variety,
    });

    if (order.rider) {
      //  const trailingStopLoss = Math.abs(order.price - order.stopLoss);
      const R = Math.abs(order.price - order.stopLoss);

      const riderOrder = {
        ...order,
        quantity: 25, // @Todo change size
        target: parseInt(order.price) + parseInt(4 * R),
        isRider: true,
        trailingStopLoss: R,
      };

      yield call(dbService.create, database.ref("actions"), {
        type: "PLACE_ORDER",
        userId: getUserId(),
        order: riderOrder,
        variety,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateOrderSaga(action) {
  const { order } = action;

  try {
    // yield call(CloudApi.updateOrder, order);

    yield call(dbService.create, database.ref("actions"), {
      type: "UPDATE_ORDER",
      userId: getUserId(),
      order,
      variety: "regular",
    });
  } catch (error) {
    console.log(error);
  }
}

function* cancelOrderSaga(action) {
  const { orderId } = action;

  try {
    yield call(dbService.create, database.ref("actions"), {
      type: "CANCEL_ORDER",
      userId: getUserId(),
      orderId,
      variety: "regular",
    });
  } catch (error) {
    console.log(error);
  }
}

function* exitOrderSaga(action) {
  const { orderId } = action;

  try {
    yield call(dbService.create, database.ref("actions"), {
      type: "EXIT_ORDER",
      userId: getUserId(),
      orderId,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createAccessTokenSaga(action) {
  try {
    const { requestToken } = action;

    yield call(dbService.create, database.ref("actions"), {
      requestToken,
      type: "CREATE_ACCESS_TOKEN",
      userId: getUserId(),
    });
  } catch (error) {
    console.log(error);
  }
}

function* addZoneWishListSaga(action) {
  const { zone } = action;

  console.log("addZoneWishListSaga", action);

  yield call(dbService.update, `zone_wishlist/${zone.id}`, zone);
}

function* deletePendingActionSaga(action) {
  const { id } = action;

  console.log("deletePendingActionSaga--", action);

  yield call(dbService.delete, database.ref(`pending_actions/${id}`));

  yield put(updateTradingZone({ id, orderStatus: "PENDING" }));
}

function* syncSagas() {
  console.log("syncSagas");
  yield all([
    syncTradesSaga(),
    syncTradingZoneSaga(),
    syncOrderSaga(),
    syncZoneWishListSaga(),
    syncPendingOrders(),
  ]);
}

export default function* settingsSaga() {
  yield all([
    yield takeLatest(userTypes.LOGIN_SUCCESS, syncSagas),
    yield takeLatest(types.ADD_TRADE_REQUEST, addTradeSaga),
    yield takeLatest(types.DELETE_TRADE_REQUEST, deleteTradeSaga),
    yield takeLatest(types.ADD_STRATEGY_REQUEST, addStrategySaga),
    yield takeLatest(types.DELETE_STRATEGY_REQUEST, deleteStrategySaga),
    yield takeLatest(types.GET_STRATEGIES_REQUEST, getStrategiesSaga),
    yield takeLatest(types.UPDATE_TRADE_REQUEST, updateTradeSaga),

    yield takeLatest(types.GET_TRADING_ZONES_REQUEST, getTradingZonesSaga),
    yield takeLatest(types.ADD_TRADING_ZONE_REQUEST, addTradingZone),
    yield takeLatest(types.UPDATE_TRADING_ZONE_REQUEST, updateTradingZoneSaga),
    yield takeLatest(types.DELETE_TRADING_ZONE_REQUEST, deleteTradingZoneSaga),

    yield takeLatest(types.PLACE_ORDER_REQUEST, placeOrderSaga),
    yield takeLatest(types.UPDATE_ORDER_REQUEST, updateOrderSaga),
    yield takeLatest(types.CREATE_ACCESS_TOKEN_REQUEST, createAccessTokenSaga),
    yield takeLatest(types.CANCEL_ORDER_REQUEST, cancelOrderSaga),
    yield takeLatest(types.ADD_ZONE_WISHLIST_REQUEST, addZoneWishListSaga),
    yield takeLatest(
      types.DELETE_ZONE_WISHLIST_REQUEST,
      deleteZoneWishListSaga
    ),
    yield takeLatest(
      types.DELETE_PENDING_ACTION_REQUEST,
      deletePendingActionSaga
    ),

    yield takeLatest(
      types.GET_ALL_TRADING_ZONES_REQUEST,
      getAllTradingZonesSaga
    ),
    yield takeLatest(types.EXIT_ORDER_REQUEST, exitOrderSaga),
  ]);
}
