import React, { useState } from 'react';
import {
  Form,
  Button,
  Card,
  Col,
  Input,
  Popover,
  Row,
  Select
} from 'antd';

import styles from './settings.less';

const layout = {
  'labelCol': {
    'span': 5

  },
  'wrapperCol': {
    'span': 5

  }
};
const tailLayout = {
  'wrapperCol': {
    'offset': 5,
    'span': 3
  }
};

const BrokerSettingsForm = () => {
  const onFinish = values => {
    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (

    <Form
      {...layout}
      layout="vertical"
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item label="Min Brokerage %">
        <Input />
      </Form.Item>

      <Form.Item label="Max Brokerage">
        <Input />
      </Form.Item>
      <Form.Item label="Exchange Transaction Charges">
        <Input />
      </Form.Item>
      <Form.Item label="Exchange Transaction Charges">
        <Input />
      </Form.Item>

      <Form.Item label="IGST">
        <Input />
      </Form.Item>
      <Form.Item label="Securities Transaction Tax">
        <Input />
      </Form.Item>
      <Form.Item label="Sebi Turnover Fees">
        <Input />
      </Form.Item>
      <Form.Item label="Stamp Duty">
        <Input />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>

  );
};

export default BrokerSettingsForm;

