/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import { version } from '../../package.json';
import rootSaga from './sagas';
import tradingService from '../service/tradingService';
import settingsTypes from './modules/settings/types';
import CloudApi from '../service/cloudApi';

export default (initialState = {}) => {
 // ======================================================
 // Window Vars Config
 // ======================================================
 window.version = version;

 // ======================================================
 // Middleware Configuration
 // ======================================================
 const middleware = [
  // This is where you add other middleware like redux-observable
 ];

 // ======================================================
 // Store Enhancers
 // ======================================================

 const enhancers = [];
 const __DEV__ = true;

 if (__DEV__) {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
   enhancers.push(devToolsExtension());
  }
 }

 const sagaMiddleware = createSagaMiddleware();

 initialState = {};

 const initApiService = store => next => action => {
  if (action.type === settingsTypes.SYNC_CREDENTIALS_SETTINGS) {
   CloudApi.init(action.credentials);

   tradingService.setCredentials(action.credentials);
  }

  // if(action.type === 'LOGIN_SUCCESS') {
  //   tradingService.setAccessToken(action.accessToken);
  //   tradingService.init();
  // }

  // continue processing this action
  return next(action);
 };

 middleware.push(initApiService);

 const store = createStore(
  rootReducer,
  initialState,
  compose(
   applyMiddleware(...middleware, sagaMiddleware),
   ...enhancers
  )
 );

 store.asyncReducers = {};

 sagaMiddleware.run(rootSaga);

 return store;
};
