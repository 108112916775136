import {
 all, put, takeEvery, call, select, takeLatest, take, cancel, fork
} from 'redux-saga/effects';
import types from './types';
import dbService from 'service/dbService';
import { getStocksSuccess } from './actions';
import { database } from 'service/firebase';
import userTypes from 'redux/modules/user/types';

const stockMapToArray = (stockMap) => {
 const stocks = [];

 if (!stockMap) {
  return stocks;
 }

 for (const [name, instrumentToken] of Object.entries(stockMap)) {
  const stock = {
   name,
   instrumentToken
  };

  stocks.push(stock);
 }

 return stocks;
};

function* addStockSaga(action) {
 try {
  const { stock } = action;

  yield call(
   dbService.patch,
   database.ref('admin/stocks'),
   { [stock.name]: stock.instrumentToken }
  );

  yield call(getStocksSaga);
 } catch (e) {
  console.log(e);
 }
}

function* deleteStockSaga(action) {
 const { id } = action;

 yield call(
  dbService.delete,
  database.ref(`admin/stocks/${id}`)
 );

 yield call(getStocksSaga);
}

function* getStocksSaga() {
 const stocks = yield call(
  dbService.read,
  database.ref('admin/stocks')
 );

 yield put(getStocksSuccess({ 'stocks': stockMapToArray(stocks),
  'instruments': stocks }));
}

export default function* settingsSage() {
 yield all([
  yield takeLatest(types.ADD_STOCK_REQUEST, addStockSaga),
  yield takeLatest(types.GET_STOCKS_REQUEST, getStocksSaga),
  yield takeLatest(types.DELETE_STOCK_REQUEST, deleteStockSaga),
  yield takeLatest(userTypes.LOGIN_SUCCESS, getStocksSaga)

 ]);
}

