import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, Input, Button } from 'antd';
import { addStock } from 'redux/modules/admin/actions';

const basicRules = [{ 'required': true,
 'message': 'Please input a value' }];

const StockForm = () => {
 const [form] = Form.useForm();
 const dispatch = useDispatch();

 const handleSave = values => {
  dispatch(addStock({
   ...values
  }));
  form.resetFields();
 };

 return (
  <Form
   form={form}
   layout="vertical"
   name="stockForm"
   hideRequiredMark
   onFinish={handleSave}
   style={{
    'padding': '24px',
    // background: '#fff',
    'border': '1px solid #d9d9d9',
    'marginBottom': '20px'
   }}>
   <Row gutter={10} >
    <Col span={5} >
     <Form.Item
      name="name"
      rules={basicRules}
      label="Stock Name">
      <Input autoComplete="off"/>
     </Form.Item>
    </Col>
    <Col span={3}>
     <Form.Item
      rules={basicRules}
      name="instrumentToken"
      label="Instrument Token">
      <Input autoComplete="off"/>
     </Form.Item>
    </Col>
   </Row>
   <Row>
    <Col span={8} style={{ 'textAlign': 'right' }}>
     <Button type="primary" htmlType="submit">
            Save
     </Button>
    </Col>
   </Row>
  </Form>
 );
};

export default StockForm;
