import {
  all,
  put,
  takeEvery,
  call,
  select,
  takeLatest,
  take,
  cancel,
  fork,
} from "redux-saga/effects";
import types from "./types";
import dbService from "service/dbService";
import { getStocksSuccess, syncCredentials, getStockSuccess } from "./actions";
import objectToArray from "utils/objectToArray";
import userTypes from "redux/modules/user/types";

function* syncCredentialsSaga() {
  const task = yield fork(dbService.sync, "settings/credentials", {
    successActionCreator: syncCredentials,
  });

  // Wait for the logout action, then stop sync
  yield take("LOGOUT");
  yield cancel(task);
}

function* syncStockSaga() {
  const task = yield fork(dbService.sync, "settings/indexes", {
    successActionCreator: getStockSuccess,
  });

  // Wait for the logout action, then stop sync
  yield take("LOGOUT");
  yield cancel(task);
}

function* addStockSaga(action) {
  try {
    const { stock } = action;

    yield call(dbService.create, "settings/stocks", stock);

    yield call(getStocksSaga);
  } catch (e) {
    console.log(e);
  }
}

function* updateStockSaga(action) {
  try {
    const { stock } = action;

    console.log("update stock", stock);

    yield call(dbService.patch, `settings/stocks/${stock.id}`, stock);

    yield call(getStocksSaga);
  } catch (e) {
    console.log(e);
  }
}

function* deleteStockSaga(action) {
  const { id } = action;

  yield call(dbService.delete, `settings/stocks/${id}`);

  yield call(getStocksSaga);
}

function* getStocksSaga() {
  const stocks = yield call(dbService.read, "settings/stocks");

  yield put(getStocksSuccess(objectToArray(stocks)));
}

function* getIndexesSaga() {
  const stocks = yield call(dbService.read, "settings/indexes");

  yield put(getStocksSuccess(objectToArray(stocks)));
}

export default function* settingsSage() {
  yield all([
    // yield takeLatest(types.ADD_STRATEGY_REQUEST, addStrategySaga),
    yield takeLatest(userTypes.LOGIN_SUCCESS, syncCredentialsSaga),
    yield takeLatest(userTypes.LOGIN_SUCCESS, syncStockSaga),
    // yield takeLatest(userTypes.LOGIN_SUCCESS, syncStockSaga),
    yield takeLatest(types.ADD_STOCK_REQUEST, addStockSaga),
    yield takeLatest(types.UPDATE_STOCK_REQUEST, updateStockSaga),
    yield takeLatest(types.GET_STOCKS_REQUEST, getStocksSaga),
    yield takeLatest(types.DELETE_STOCK_REQUEST, deleteStockSaga),
  ]);
}
