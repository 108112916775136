import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StandardTable from 'components/StandardTable';
// import { getTransactions  as getTransactionsAction } from 'redux/modules/banking/actions';
import { getTransactions } from 'redux/modules/banking/selectors';
import { DeleteTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';

const TransactionList = () => {
  // useEffect(() => {
  //   console.log("\n\n\ndispatch")
  //   dispatch(getTransactionsAction())
  // }, [dispatch]);

  const transactions = useSelector(getTransactions);

  const columns = [
    {
      'title': 'No',
      'dataIndex': 'index',
      'key': 'index',
      'width': '20px'
    },
    {
      'title': 'Date',
      'dataIndex': 'date',
      'key': 'date'
    },
    {
      'title': 'Action',
      'dataIndex': 'action',
      'key': 'action'
    },
    {
      'title': 'Amount',
      'dataIndex': 'amount',
      'key': 'amount'
    },
    {
      'render': (item)=> (<>
        <Popconfirm
          title="Are you sure delete this Transaction">
          <a><DeleteTwoTone /></a>
        </Popconfirm>
      </>)
    }

  ];

  return (
    <StandardTable columns={columns} dataSource={[transactions]} />
  );
};

export default TransactionList;
