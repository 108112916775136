import { buffers, eventChannel } from 'redux-saga';
import { call, fork, cancelled, put, take } from 'redux-saga/effects';
import { database, getUserId } from './firebase';

export const getRef = (pathOrRef) => (typeof pathOrRef === 'string' ? database.ref(getDbUrl(pathOrRef)) : pathOrRef);

function* syncChannel(channel, options) {
 const { successActionCreator, failureActionCreator, transform } = options;

 try {
  while (true) {
   const data = yield take(channel);
   const transformedData = transform ? transform(data) : data;

   yield put(successActionCreator(transformedData));
  }
 } catch (err) {
  /* eslint-disable no-console */
  if (failureActionCreator) {
   yield put(failureActionCreator(err));
  } else {
   console.error(
    'The following error has been ignored because no `failureActionCreator` has been set:',
    err
   );
  }
 } finally {
  if (yield cancelled()) {
   channel.close();
  }
 }
}

function* read(pathOrRef) {
 const ref = getRef(pathOrRef);
 const result = yield call([ref, ref.once], 'value');

 return result.val();
}

function* create(pathOrRef, data) {
 const ref = getRef(pathOrRef);
 const result = yield call([ref, ref.push], data);

 return result.key;
}

function* update(pathOrRef, data) {
 const ref = getRef(pathOrRef);

 yield call([ref, ref.set], data);
}

function* patch(pathOrRef, data) {
 const ref = getRef(pathOrRef);

 yield call([ref, ref.update], data);
}

function* _delete(pathOrRef) {
 const ref = getRef(pathOrRef);

 yield call([ref, ref.remove]);
}

const channel = (pathOrRef, event = 'value', buffer = buffers.none()) => {
 const ref = getRef(pathOrRef);

 const channel = eventChannel(emit => {
  const callback = ref.on(event, dataSnapshot =>
   emit({
    'snapshot': dataSnapshot,
    'value': dataSnapshot.val()
   })
  );

  // Returns unsubscribe function
  return () => ref.off(event, callback);
 }, buffer);

 return channel;
};

const defaultTransform = data => data.value;

function* sync(pathOrRef, options, event) {
 const _channel = yield call(channel, pathOrRef, event);

 yield fork(syncChannel, _channel, {
  'transform': defaultTransform,
  ...options
 });
}

const getDbUrl = (url) => {
 const userId = getUserId();

 return `users/${userId}/${url}`;
 // iOt7QxsZDQbU2cuDWtXc5U4md6x2
 //  return `users/iOt7QxsZDQbU2cuDWtXc5U4md6x2/${url}`;
};

export default {
 read,
 create,
 update,
 patch,
 'delete': _delete,
 channel,
 sync,
 getDbUrl
};
