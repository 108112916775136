import React from 'react';
import PropTypes from 'prop-types';
import useProviderAuth from '../hooks/useProviderAuth';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
 const session = useProviderAuth();

 return (
  <AuthContext.Provider value={session}>
   {children}
  </AuthContext.Provider>
 );
};

AuthProvider.propTypes = {
 'children': PropTypes.element.isRequired
};

export default AuthProvider;
