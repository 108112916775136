import { createSelector } from "reselect";
import moment from "moment";

const getSettings = (state) => (state && state.settings) || {};

export const getStocks = (state) =>
  (state && state.settings && state.settings.stocks) || [];
export const isCredentialsLoaded = (state) =>
  (state && state.settings && state.settings.credentialsLoaded) || false;

export const getCredentials = createSelector(
  [getSettings],
  (settings) => settings.credentials || {}
);

export const hasValidAccessToken = createSelector(
  [getCredentials],
  (credentials) =>
    credentials.accessToken &&
    credentials.tokenLastUpdatedDate == moment().format("YYYYMMDD")
);

export const getLoginUrl = createSelector(
  [getCredentials],
  (credentials) =>
    "https://kite.zerodha.com/connect/login?api_key=" + credentials.apiKey
);

export const getSelectedStock = createSelector(
  [getSettings],
  (settings) => settings.selectedStock || {}
);

export const getStock = createSelector(
  [getSettings],
  (settings) => settings.stock || {}
);

export const getIndexes = createSelector(
  [getSettings],
  (settings) => settings.indexes || {}
);

