import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StandardTable from 'components/StandardTable';
import { getZoneWishList } from 'redux/modules/trading/selectors';
import { placeLimitOrder, placeSLOrder, deleteZoneWishList } from 'redux/modules/trading/actions';
import { DeleteOutlined } from '@ant-design/icons';
import { getOrder, buttonType } from './utils';

import {
 Button, Popconfirm
} from 'antd';

const WishList = () => {
 const wishList = useSelector(getZoneWishList);
 const dispatch = useDispatch();

 const columns = [
  {
   'title': 'No',
   'width': '20px',
   'render': (text, row, index) => index + 1
  },

  {
   'title': 'Stock',
   'dataIndex': 'stock',
   'key': 'stock',
   //  'sortDirections': 'ascend',
   'sorter': (a, b) => a && a.stock && a.stock.localeCompare(b.stock)
   //  'defaultSortOrder': 'ascend'
   //  'render': (zone)=> (
   //   <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
   //    {zone.stock}
   //   </a>)

  },

  // {
  //  'title': 'Action',
  //  'dataIndex': 'action',
  //  'key': 'action',
  //  'sorter': (a, b) => a.action.localeCompare(b.action)
  // },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'key': 'price',
   'sorter': (a, b) => parseInt(a.price) - parseInt(b.price)

  },
  {
   'title': 'Stop Loss',
   'dataIndex': 'stopLoss',
   'key': 'stopLoss'
  },
  {
   'title': 'Target',
   'dataIndex': 'target',
   'key': 'target'
  },
  {
   'title': 'Risk',
   'dataIndex': 'risk',
   'key': 'risk',
   'render': (risk) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{risk}</span>),
   'align': 'center'
  },
  {
   'title': '% Risk',
   'dataIndex': 'riskPercentage',
   'key': 'riskPercentage',
   'render': (riskPercentage) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{riskPercentage}%</span>),
   'align': 'center'
  },
  {
   'title': 'Planned R:R',
   'dataIndex': 'riskReward',
   'key': 'riskReward',
   'align': 'center',
   'render': (riskReward) => <span style={{ 'fontWeight': '500' }}>1 : {riskReward}</span>
  },
  {
   'align': 'center',
   'render': (trade)=>
   //  trade.orderStatus === 'PENDING' &&
    trade.orderStatus !== 'OPEN' &&
         <>
          <Button
           style={{ 'fontWeight': '500' }} onClick={() => {
            dispatch(placeLimitOrder(getOrder(trade)));
            dispatch(deleteZoneWishList(trade.id));
           }
           } type={buttonType[trade.action]}>
           {trade.action}
          </Button>
          <Button
           style={{ 'fontWeight': '500',
            'marginLeft': 20 }} onClick={() => {
            dispatch(placeSLOrder(getOrder(trade)));
            dispatch(deleteZoneWishList(trade.id));
           }} type={buttonType[trade.action]}>
           {trade.action} SL
          </Button>
         </>

  },
  {
   'render': (trade)=> (<Popconfirm
    title="Are you sure delete this trade zone"
    onConfirm={() => dispatch(deleteZoneWishList(trade.id))}>
    <a><DeleteOutlined /></a>
   </Popconfirm>)
  }
 ];

 return (
  <div>
   <StandardTable columns={columns} dataSource={wishList} />
  </div>
 );
};

export default WishList;
