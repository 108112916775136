
import { createSelector } from 'reselect';

const getAdmin = state => (state && state.admin) || {};

export const getStocks = createSelector(
 [getAdmin],
 admin => admin.stocks || []
);
export const getInstruments = createSelector(
 [getAdmin],
 admin => admin.instruments || {}
);
