import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

!firebase.apps.length
  ? firebase.initializeApp({
    apiKey: "AIzaSyCKG5PB6zzla7pgLUowsBfvK4dATbxmrXo",
    authDomain: "durga-nse.firebaseapp.com",
    databaseURL: "https://durga-nse.firebaseio.com/",
    projectId: "durga-nse",
    storageBucket: "durga-nse.appspot.com",
    messagingSenderId: "460459440848"
  })
  : firebase.app();

const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const database = firebase.database();

const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

const signOut = () => auth.signOut();

const getUserId = () => auth && auth.currentUser && auth.currentUser.uid;

const getIdToken = async () => {
  const idToken =
    (await auth) && auth.currentUser && auth.currentUser.getIdToken();

  return idToken;
};

export {
  firebase,
  database,
  auth,
  signInWithGoogle,
  signOut,
  getUserId,
  getIdToken
};
