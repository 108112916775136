import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import StandardTable from 'components/StandardTable';
import { cancelOrder, deletePendingAction, exitOrder } from 'redux/modules/trading/actions';

import OrderForm from './OrderForm';
import {
 Modal, Popconfirm, Button
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ActiveOrders = ({ orders = [] }) => {
 const [currentOrder, setCurrentOrder] = useState({});
 const [isModalVisible, setModalVisible] = useState(false);
 const dispatch = useDispatch();

 const editOrder = (order) => {
  console.log('\n\n-------edit order ', order);
  setCurrentOrder(order);
  setModalVisible(true);
 };

 const handleSave = () => {
  setCurrentOrder({});
  setModalVisible(false);
 };

 const columns = [
  {
   'title': 'No',
   'width': '20px',
   'render': (text, row, index) => index + 1
  },

  {
   'title': 'Stock',
   'dataIndex': 'stock',
   'key': 'stock',
   // sortDirections: 'ascend',
   'sorter': (a, b) => a.stock.localeCompare(b.stock),
   'defaultSortOrder': 'ascend'
   // render: (order)=> {
   //   return (
   //     <a title="Edit" href="javascript:void(0)"  onClick={() => editOrder(order)}>
   //       {order.stock}
   //   </a>)
   // }
  },
  {
   'title': 'Action',
   //  'dataIndex': 'transactionType',
   'key': 'transactionType',
   'render': (order)=> (
    order.transactionType || order.transaction_type)
  },
  {
   'title': 'Quantity',
   'dataIndex': 'quantity',
   'key': 'quantity'
  },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'key': 'price'
  },

  {
   'title': 'Stop Loss',
   'dataIndex': 'stopLoss',
   'key': 'stopLoss'
  },
  {
   'title': 'Trailing Stop Loss',
   'dataIndex': 'trailingStopLoss',
   'key': 'trailingStopLoss'
  },
  {
   'title': 'Target',
   'dataIndex': 'target',
   'key': 'target'
  },
  {
   'title': 'Breakeven',
   'dataIndex': 'breakeven',
   'key': 'breakeven',
   'render': (breakeven) => <span style={{ 'fontWeight': '500' }}>{breakeven}</span>
  },
  {
   'title': 'Risk',
   'dataIndex': 'risk',
   'key': 'risk',
   'render': (risk) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{risk}</span>),
   'align': 'center'
  },
  {
   'title': '% Risk',
   'dataIndex': 'riskPercentage',
   'key': 'riskPercentage',
   'render': (riskPercentage) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{riskPercentage}%</span>),
   'align': 'center'
  },
  {
   'title': 'Planned R:R',
   'dataIndex': 'riskReward',
   'key': 'riskReward',
   'align': 'center',
   'render': (riskReward) => <span style={{ 'fontWeight': '500' }}>1 : {riskReward}</span>
  },
  {
   'render': (order)=> {
    if (order.status === 'OPEN') {
     return (<Button
      style={{ 'fontWeight': '500' }} onClick={() => dispatch(cancelOrder(order.orderId))}
      type="primary">Cancel Order</Button>);
    }
    if (order.status === 'PENDING') {
     return (
      <a onClick={() => dispatch(deletePendingAction(order.id))}><DeleteOutlined /></a>
     );
    }

    if (order.status === 'TRIGGER PENDING') {
     return (<Button
      style={{ 'fontWeight': '500' }} onClick={() => dispatch(exitOrder(order.orderId))}
      type="primary">Exit Order</Button>);
    }
   }

  }
 ];

 return (
  <>
   <StandardTable columns={columns} dataSource={orders} />
   <Modal
    destroyOnClose
    maskClosable={false}
    width={900}
    footer={null}
    onCancel={() => {
     setModalVisible(false); setCurrentOrder({});
    }}
    title="Order"
    visible={isModalVisible}>
    <OrderForm order={currentOrder} key={currentOrder.orderId} handleSave={handleSave}/>
   </Modal>
  </>
 );
};

export default ActiveOrders;
