import React from "react";
import PropTypes from "prop-types";
import { Route, useNavigate } from "react-router-dom";

import DefaultLayout from "pages/layouts/DefaultLayout";
import AuthLayout from "pages/layouts/AuthLayout";
import PageLoading from "components/PageLoading";
import useAuth from "hooks/useAuth";
import { redirect } from "helpers/routing";
import { useSelector } from "react-redux";
import {
  hasValidAccessToken,
  isCredentialsLoaded,
  getLoginUrl,
} from "redux/modules/settings/selectors";
import DashBoard from "pages/Dashboard";

export default function RouteWrapper(props) {
  const { component: Component, isPrivate, isTrading, path, ...rest } = props;
  const navigate = useNavigate();

  const {
    session: { isSignedIn, loading, isApproved, isClient },
  } = useAuth();
  const hasTradingAccess = useSelector(hasValidAccessToken);
  // const credentials = useSelector(getCredentials);
  const hasCredentials = useSelector(isCredentialsLoaded);
  const loginUrl = useSelector(getLoginUrl);

  console.log("props", props);

  console.log(
    "credentials---------",
    loading,
    hasCredentials,
    hasTradingAccess
  );

  if (loading) {
    return <PageLoading />;
  }

  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !isSignedIn) {
    navigate("/", { replace: true });
    // return <Redirect to="/" />;
  }

  console.log("isPrivate---------", isPrivate, !isSignedIn, path);

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (!isPrivate && isSignedIn) {
    // return <Redirect to="/dashboard" />;
    navigate("/dashboard", { replace: true });
  }

  console.log("Loading route---------111");

  if (isPrivate && !isApproved) {
    navigate("/403", { replace: true });
    // return <Redirect to="/403" />;
  }

  const Layout = isSignedIn ? AuthLayout : DefaultLayout;

  console.log("Loading route---------111");

  if (isTrading) {
    if (!hasCredentials) {
      return <PageLoading />;
    }
    if (!hasTradingAccess) {
      redirect(loginUrl);
    }
  }

  console.log("Loading route---------");

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return (
    <Layout>
      <Route {...rest} element={isClient ? <DashBoard /> : <Component />} />
    </Layout>
  );
}

// RouteWrapper.propTypes = {
//  'isPrivate': PropTypes.bool,
//  'component': PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
// };

// RouteWrapper.defaultProps = {
//  'isPrivate': false
// };
