import React, { useState, useEffect } from "react";

import { Form, Row, Col, Input, Button, Select, Tag } from "antd";
import { getClosedPosition } from "utils/brokerage";
import { useDispatch, useSelector } from "react-redux";
import { getIndexes, getStock } from "redux/modules/settings/selectors";
import stocks from "./stocks.json";
import futureMap from "./futures";
import { getUserId } from "../../service/firebase";

import styles from "./styles.less";

const { Option } = Select;

const basicRules = [{ required: true, message: "Please input a value" }];

const FutureForm = ({ data = {}, handleSave }) => {
  const [form] = Form.useForm();
  const indexes = useSelector(getIndexes);
  console.log("data :>> ", data);
  //  const stock = useSelector(getStock);
  const [stock, setStock] = useState(futureMap[data.stock]);

  const [risk, setRisk] = useState(data.risk || 0);
  const [reward, setReward] = useState(data.reward || 0);
  const [quantity, setQuantity] = useState(
    data.quantity || stock?.quantity || 0
  );
  //  const [action, setAction] = useState(data.action || 'No Trade');

  const onFinish = (values) => {
    //   if (data.id) {
    //    dispatch(updateTradingZone({
    //     ...data,
    //     ...values,
    //     action,
    //     'id': data.id,
    //     risk,
    //     reward,
    //     'breakeven': getBreakeven(),
    //     trailingStopLoss,
    //     targetBreak,
    //     'orderStatus': 'PENDING',
    //     strike,
    //     'lotSize': 25

    //    }));
    //   } else {
    //    dispatch(addTradingZone({
    //     ...values,
    //     action,
    //     // date:  moment().format('YYYYMMDD'),
    //     risk,
    //     reward,
    //     'breakeven': getBreakeven(),
    //     'orderStatus': 'PENDING',
    //     trailingStopLoss,
    //     targetBreak,
    //     strike,
    //     'lotSize': 25
    //    }));
    //   }
    const { action } = values;

    console.log("stock", stock);

    const zone = {
      ...data,
      ...values,
      ...stock,
      risk,
      reward,
      action,
      exchange: "NFO",
      status: "PENDING",
      createdBy: getUserId()
    };

    handleSave(zone);

    form.resetFields();
  };

  const handleRisk = () => {
    const price = parseFloat(form.getFieldValue("price") || 0);
    const stopLoss = form.getFieldValue("stopLoss") || 0;
    const target = parseFloat(form.getFieldValue("target") || 0);

    // const stock =
    //   parseFloat(price || 0) > 25000 ? indexes.banknifty : indexes.nifty;

    // setStock(stock);

    console.log('handleRisk :>> ', stock);

    const quantity = stock.quantity;

    console.log("price - stopLoss", price, stopLoss, quantity);

    const risk = Math.abs(price - stopLoss) * quantity;
    // const riskPercentage = (risk / accountBalance) * 100;
    const reward = Math.abs(target - price) * quantity; // - breakeven;

    // console.log('price - reward', risk, reward, target, price, quantity);

    setRisk(risk.toFixed(2));
    setReward(reward);
  };

  const price = parseFloat(form.getFieldValue("price") || 0) || data.price || 0;
  const target =
    parseFloat(form.getFieldValue("target") || 0) || data.target || 0;

  const handleStockChange = (name) => {
    console.log("handleStockChange :>> ", futureMap[name]);
    setStock(futureMap[name]);
  };

  console.log('stock :>> ', stock);

  return (
    <Form
      form={form}
      layout="vertical"
      name="advanced_search"
      onFinish={onFinish}
      hideRequiredMark
      className={styles.orderForm}
      initialValues={{
        ...data,
      }}
      style={{
        padding: "24px",
        // background: '#fff',
        border: "1px solid #d9d9d9",
        marginBottom: "20px",
        // width: '90%'
      }}
    >
      <Row gutter={24}>
        <Col>
          <Form.Item rules={basicRules} name="stock" label="Stock">
            <Select
              defaultValue=""
              showSearch
              onChange={handleStockChange}
              style={{ width: "200px" }}
            >
              {stocks.map((item) => (
                <Option key={item.tradingSymbol} value={item.tradingSymbol}>
                  {item.tradingSymbol}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={16}> */}
        {/* <Form.Item
      rules={basicRules}
      name="trend"
      label="Trend">
      <Select defaultValue="" showSearch style={{ 'width': '110px' }}>
       <Option key="up" value="Up">Up</Option>
       <Option key="down" value="Down">Down</Option>
      </Select>
     </Form.Item> */}
        {/* <Row justify="space-between"> */}
        {/* <Col>
     <Form.Item
      rules={basicRules}
      name="trend1hr"
      label="1 hour Trend">
      <Select onChange={handleTrend} defaultValue="" showSearch style={{ 'width': '110px' }}>
       <Option key="up" value="Up">Up</Option>
       <Option key="down" value="Down">Down</Option>
      </Select>
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      name="trend15min"
      label="15 min Trend">
      <Select onChange={handleTrend} defaultValue="" showSearch style={{ 'width': '110px' }}>
       <Option key="up" value="Up">Up</Option>
       <Option key="down" value="Down">Down</Option>
      </Select>
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      name="trend5min"
      label="5 min Trend">
      <Select onChange={handleTrend} defaultValue="" showSearch style={{ 'width': '110px' }}>
       <Option key="up" value="Up">Up</Option>
       <Option key="down" value="Down">Down</Option>
      </Select>
     </Form.Item>
    </Col> */}
        {/* <Col>
     <Form.Item
      label="Action">
      <b>{action}</b>
     </Form.Item>

    </Col> */}

        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "70px" }}
            name="price"
            label="Entry Price"
          >
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "80px" }}
            name="stopLoss"
            label="Stop Loss"
          >
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "70px" }}
            name="target"
            label="Target"
          >
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item rules={basicRules} name="action" label="Order Type">
            <Select defaultValue="" showSearch style={{ width: "110px" }}>
              <Option key="buy" value="BUY">
                BUY
              </Option>
              <Option key="sell" value="SELL">
                SELL
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            style={{ alignItems: "center", alignContent: "center" }}
            label="Planned R:R"
          >
            <span style={{ fontWeight: "bold" }}>{`1: ${
              isNaN((reward / risk).toFixed(2))
                ? ""
                : (reward / risk).toFixed(2)
            }`}</span>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item style={{ alignItems: "centre" }} label="Target Points">
            <Tag style={{ fontWeight: "bold" }}>{Math.abs(target - price)}</Tag>
          </Form.Item>
        </Col>
        {/* </Row>
    </Col> */}
      </Row>
      <Row gutter={24}>
        {/* <Col>
     <Form.Item
      rules={basicRules}
      name="pattern"
      label="Chart Pattern">
      <Select defaultValue="" showSearch style={{ 'width': '130px' }}>
       {chartPatterns.map(item => (
        <Option key={item} value={item}>{item}</Option>
       ))}
      </Select>
     </Form.Item>
    </Col>
    <Col>
     <Form.Item
      rules={basicRules}
      style={{ 'width': '220px' }}
      name="chartImage"
      label="Chart Image">
      <Input autoComplete="off"/>
     </Form.Item>
    </Col> */}

        {/* <Col>
     <Form.Item
      style={{ 'alignItems': 'centre' }}
      label="Loss">
      <Tag
       color="red" style={{ 'fontWeight': 'bold'
       }}>{risk}</Tag>
     </Form.Item>
    </Col> */}
      </Row>
      <Row justify="end">
        <Col>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FutureForm;
