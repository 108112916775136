import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, Input, Button } from 'antd';
import { addStrategy } from 'redux/modules/trading/actions';

const { TextArea } = Input;
const basicRules = [{ 'required': true,
  'message': 'Please input a value' }];

const NewStrategy = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleSave = values => {
    dispatch(addStrategy({
      ...values
    }));
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="strategy"
      hideRequiredMark
      onFinish={handleSave}
      style={{
        'padding': '24px',
        // background: '#fff',
        'border': '1px solid #d9d9d9',
        'marginBottom': '20px'
      }}>
      <Row gutter={24}>
        <Col span={8} >
          <Form.Item
            name="name"
            rules={basicRules}
            label="Strategy Name">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={basicRules}
            name="checkList"
            label="Strategy Check List">
            <TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={basicRules}
            name="description"
            label="Strategy Description">
            <TextArea rows={4} />
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={4} offset={20} style={{ 'textAlign': 'right' }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NewStrategy;
