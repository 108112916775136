import typesGenerator from 'utils/typesGenerator';

function generateTypes(type) {
 return typesGenerator('trades')(type);
}

const types = {
 ...generateTypes('CREATE_ACCESS_TOKEN'),
 ...generateTypes('ADD_TRADE'),
 ...generateTypes('UPDATE_TRADE'),
 ...generateTypes('DELETE_TRADE'),
 ...generateTypes('ADD_STRATEGY'),
 ...generateTypes('DELETE_STRATEGY'),
 ...generateTypes('GET_STRATEGIES'),

 ...generateTypes('ADD_TRADING_ZONE'),
 ...generateTypes('DELETE_TRADING_ZONE'),
 ...generateTypes('UPDATE_TRADING_ZONE'),
 ...generateTypes('GET_TRADING_ZONES'),
 ...generateTypes('PLACE_ORDER'),
 ...generateTypes('UPDATE_ORDER'),
 ...generateTypes('CANCEL_ORDER'),
 ...generateTypes('ADD_ZONE_WISHLIST'),
 ...generateTypes('DELETE_ZONE_WISHLIST'),
 ...generateTypes('DELETE_PENDING_ACTION'),
 ...generateTypes('DELETE_PENDING_ACTION'),
 ...generateTypes('GET_ALL_TRADING_ZONES'),
 ...generateTypes('EXIT_ORDER'),

 'SYNC_TRADES': 'SYNC_TRADES',
 'SYNC_ORDER_SUCCESS': 'trades/SYNC_ORDER_SUCCESS',
 'SYNC_ZONE_WISHLIST_SUCCESS': 'trades/SYNC_ZONE_WISHLIST_SUCCESS',
 'SYNC_PENDING_ORDERS_SUCCESS': 'SYNC_PENDING_ORDERS_SUCCESS'

};

export default types;
