/* eslint-disable import/prefer-default-export */

export const getClosedPosition = (buyPrice, sellPrice, quantity) => {
 if (isNaN(quantity) || (isNaN(buyPrice) && isNaN(sellPrice))) {
  return 0;
 }
 if (isNaN(buyPrice) || buyPrice === 0) {
  buyPrice = 0;
 }
 if (isNaN(sellPrice) || sellPrice === 0) {
  sellPrice = 0;
 }
 buyPrice = parseFloat(buyPrice);
 sellPrice = parseFloat(sellPrice);
 const brokerage_buy = ((buyPrice * quantity * 0.0003) > 20) ? 20 : parseFloat(parseFloat(buyPrice * quantity * 0.0003).toFixed(2));
 const brokerage_sell = ((sellPrice * quantity * 0.0003) > 20) ? 20 : parseFloat(parseFloat(sellPrice * quantity * 0.0003).toFixed(2));
 const brokerage = parseFloat(parseFloat(brokerage_buy + brokerage_sell).toFixed(2));
 const turnover = parseFloat(parseFloat((buyPrice + sellPrice) * quantity).toFixed(2));
 const stt_total = Math.round(parseFloat(parseFloat((sellPrice * quantity) * 0.00025).toFixed(2)));
 const exc_trans_charge = parseFloat(parseFloat(0.0000325 * turnover).toFixed(2));
 const cc = 0;
 const stax = parseFloat(parseFloat(0.18 * (brokerage + exc_trans_charge)).toFixed(2));
 const stamp_charges = parseFloat(parseFloat((buyPrice * quantity) * 0.00003).toFixed(2));
 const sebi_charges = parseFloat(parseFloat(turnover * 0.0000005).toFixed(2));
 const totalTax = parseFloat(parseFloat(brokerage + stt_total + exc_trans_charge + cc + stax + sebi_charges + stamp_charges).toFixed(2));

 let breakeven = parseFloat(parseFloat(totalTax / quantity).toFixed(2));

 breakeven = isNaN(breakeven) ? 0 : breakeven;
 const profit = parseFloat((sellPrice - buyPrice) * quantity);
 const netProfit = parseFloat(parseFloat(((sellPrice - buyPrice) * quantity) - totalTax).toFixed(2));

 return {
  profit,
  breakeven,
  netProfit,
  totalTax
 };
};
