import { createSelector } from "reselect";
import moment from "moment";

// export const getTrades = state => state.trading.trades ? state.trading.trades.sort((a, b) => (parseInt(a.date) - parseInt(b.date))) :  [];
export const getStrategies = (state) => state.trading.strategies || [];
export const getTradingZones = (state) => state.trading.tradingZones || [];
export const getOrders = (state) => state.trading.orders || [];
export const getZoneWishList = (state) => state.trading.zoneWishList || [];
export const getPendingOrders = (state) => state.trading.pendingOrders || [];
export const getAllTradingZones = (state) =>
  state.trading.allTradingZones || [];

export const hasZoneWishList = createSelector(
  [getZoneWishList],
  (wishList) => wishList && wishList.length > 0
);

const gainRanges = [
  {
    name: "Loss",
    range: ">-5%",
    min: -100,
    max: -5,
  },
  {
    name: "Loss",
    range: "-4% to -5%",
    min: -5,
    max: -4,
  },
  {
    name: "Loss",
    range: "-3% to -4%",
    min: -4,
    max: -3,
  },
  {
    name: "Loss",
    range: "-2% to -3%",
    min: -3,
    max: -2,
  },
  {
    name: "Loss",
    range: "-1% to -2%",
    min: -2,
    max: -1,
  },
  {
    name: "Loss",
    range: "0% to -1%",
    min: -1,
    max: 0,
  },
  {
    range: "0% to 1%",
    min: 0,
    max: 1,
  },
  {
    name: "Gain",
    range: "1% to 2%",
    min: 1,
    max: 2,
  },
  {
    name: "Gain",
    range: "2% to 3%",
    min: 2,
    max: 3,
  },
  {
    name: "Gain",
    range: "3% to 4%",
    min: 3,
    max: 4,
  },
  {
    name: "Gain",
    range: "4% to 5%",
    min: 4,
    max: 5,
  },
  {
    name: "Gain",
    range: ">5%",
    min: 5,
    max: 100,
  },
];

const timeRanges = [
  {
    time: "9:15 am - 10:00 am",
    min: moment("09:15 am", "hh:mm a"),
    max: moment("10:00 am", "hh:mm a"),
  },
  {
    time: "10:00 am - 11:00 am",
    min: moment("10:00 am", "hh:mm a"),
    max: moment("11:00 am", "hh:mm a"),
  },
  {
    time: "11:00 am - 12:00 pm",
    min: moment("11:00 am", "hh:mm a"),
    max: moment("12:00 pm", "hh:mm a"),
  },
  {
    time: "12:00 pm - 01:00 pm",
    min: moment("12:00 pm", "hh:mm a"),
    max: moment("01:00 pm", "hh:mm a"),
  },
  {
    time: "01:00 pm - 02:00 pm",
    min: moment("01:00 pm", "hh:mm a"),
    max: moment("02:00 pm", "hh:mm a"),
  },
  {
    time: "02:00 pm - 03:00 pm",
    min: moment("02:00 pm", "hh:mm a"),
    max: moment("03:00 pm", "hh:mm a"),
  },
];

export const getActiveOrders = createSelector([getOrders], (orders) =>
  orders.filter(
    (order) => order.status !== "COMPLETED" && order.tag === "parent"
  )
);

export const getTrades = createSelector([getOrders], (orders) => {
  const trades = orders.filter((order) => order.status === "COMPLETED");

  if (trades.length) {
    trades.sort((a, b) => parseInt(a.date) - parseInt(b.date));
  }

  return trades;
});

const getChangePercentages = createSelector([getTrades], (trades) =>
  trades.map((trade) => parseFloat(trade.accountChange))
);

export const getPerformanceChartData = createSelector(
  [getChangePercentages],
  (accountChanges) => {
    const chartData = [];

    gainRanges.forEach(({ name, range, min, max }) => {
      const result = accountChanges.reduce(
        (data, value) => {
          // console.log('value-----', value)

          if (min <= value && max > value) {
            data.count = (data.count || 0) + 1;
          }

          return data;
        },
        { count: 0 }
      );

      chartData.push({ ...result, name, range });
    });

    return chartData;
  }
);

export const getOpenTrades = createSelector([getTrades], (trades) =>
  trades.filter((trade) => !trade.netProfit)
);

export const getClosedTrades = createSelector([getTrades], (trades) =>
  trades.filter((trade) => !!trade.netProfit)
);

export const getTotalTrades = createSelector(
  [getClosedTrades],
  (trades) => trades.length
);

export const getWinTrades = createSelector(
  [getClosedTrades],
  (trades) => trades.filter((trade) => trade.netProfit >= 0) || []
);

export const getWinTradesCount = createSelector(
  [getWinTrades],
  (trades) => trades.length
);

export const getLossTrades = createSelector(
  [getClosedTrades],
  (trades) => trades.filter((trade) => parseFloat(trade.netProfit) < 0) || []
);

export const getLossTradesCount = createSelector(
  [getLossTrades],
  (trades) => trades.length
);

export const getWinRate = createSelector(
  [getWinTradesCount, getLossTradesCount],
  (win, loss) => ((win / (win + loss)) * 100).toFixed(2)
);

export const getLongWinTrades = createSelector([getWinTrades], (trades) =>
  trades.filter((trade) => trade.action === "BUY")
);

export const getLongLossTrades = createSelector([getLossTrades], (trades) =>
  trades.filter((trade) => trade.action === "BUY")
);

export const getLongWinTradeCount = createSelector(
  [getLongWinTrades],
  (trades) => trades.length
);

export const getLongLossTradeCount = createSelector(
  [getLongLossTrades],
  (trades) => trades.length
);

export const getShortWinTrades = createSelector([getWinTrades], (trades) =>
  trades.filter((trade) => trade.action === "SELL")
);

export const getLossShortTrades = createSelector([getLossTrades], (trades) =>
  trades.filter((trade) => trade.action === "SELL")
);

export const getShortWinTradeCount = createSelector(
  [getShortWinTrades],
  (trades) => trades.length
);

export const getLossShortTradeCount = createSelector(
  [getLossShortTrades],
  (trades) => trades.length
);

export const getSuccessRateChartData = createSelector(
  [
    getLongWinTradeCount,
    getLongLossTradeCount,
    getShortWinTradeCount,
    getLossShortTradeCount,
  ],
  (longSuccess, longFaild, shortSuccess, shortFailed) => [
    { value: 10, type: "Success", name: "Long " },
    { value: 2, type: "Success", name: "Short" },
    { value: 4, type: "Failed", name: "Long" },
    { value: 8, type: "Failed", name: "Short " },
  ]
);

export const getAverageDiscipline = createSelector(
  [getClosedTrades, getTotalTrades],
  (trades, total) =>
    trades.reduce(
      (a, b) => parseFloat(a.discipline) + parseFloat(b.discipline)
    ) / total
);

export const getTotalGain = createSelector(
  [getWinTrades],
  (trades) => trades.reduce((total, trade) => total + trade.netProfit, 0) / 3
);

export const getTotalLoss = createSelector(
  [getLossTrades],
  (trades) => trades.reduce((total, trade) => total + trade.netProfit, 0) / 3
);

export const getNetProfit = createSelector(
  [getTotalGain, getTotalLoss],
  (gain, loss) => gain + loss
);

export const getMaxProfit = createSelector(
  [getWinTrades],
  (trades) => Math.max(...trades.map((o) => o.netProfit)) / 3
);

export const getMaxLoss = createSelector(
  [getLossTrades],
  (trades) => Math.min(...trades.map((o) => o.netProfit)) / 3
);

const getSuccessTradeTimings = createSelector([getWinTrades], (trades) =>
  trades.map((trade) => moment(trade.entryTime, "hh:mm a"))
);

export const getHourlySuccessRate = createSelector(
  [getSuccessTradeTimings],
  (timings) => {
    const chartData = [];

    gainRanges.forEach(({ time, min, max }) => {
      const result = timings.reduce(
        (data, value) => {
          if (min <= value && max > value) {
            data.count = (data.count || 0) + 1;
          }

          return data;
        },
        { count: 0 }
      );

      chartData.push({ ...result, time });
    });

    return chartData;
  }
);

export const getExitAnalysis = createSelector(
  [getTrades, getWinTrades, getLossTrades],
  (trades, winTrades, lossTrades) => {
    const exitReasons = [
      "Hit Trailing Stop Loss",
      "Hit Stop Loss",
      "Hit Target",
      "Market Exit",
    ];

    const analysis = [];

    exitReasons.forEach((exitReason) => {
      const initialData = { exitReason, total: 0, win: 0, loss: 0 };

      const total = trades.reduce((data, trade) => {
        if (trade.exitReason && trade.exitReason.includes(exitReason)) {
          data.total += 1;
        }

        return data;
      }, initialData);

      const win = winTrades.reduce((data, trade) => {
        if (trade.exitReason && trade.exitReason.includes(exitReason)) {
          data.win += 1;
        }

        return data;
      }, total);

      const loss = lossTrades.reduce((data, trade) => {
        if (trade.exitReason && trade.exitReason.includes(exitReason)) {
          data.loss += 1;
        }

        return data;
      }, win);

      analysis.push(loss);
    });

    return analysis;
  }
);

export const getProcessAnalysis = createSelector(
  [getTrades, getWinTrades, getLossTrades],
  (trades, winTrades, lossTrades) => {
    const processList = [
      "According To Plan",
      "Broke Rules",
      "Too Early",
      "Too Late",
      "Revenge Trade",
    ];

    const analysis = [];

    processList.forEach((process) => {
      const initialData = { process, total: 0, win: 0, loss: 0 };

      const total = trades.reduce((data, trade) => {
        if (trade.management && trade.management.includes(process)) {
          data.total += 1;
        }

        return data;
      }, initialData);

      const win = winTrades.reduce((data, trade) => {
        if (trade.management && trade.management.includes(process)) {
          data.win += 1;
        }

        return data;
      }, total);

      const loss = lossTrades.reduce((data, trade) => {
        if (trade.management && trade.management.includes(process)) {
          data.loss += 1;
        }

        return data;
      }, win);

      analysis.push(loss);
    });

    return analysis;
  }
);

export const getEntryTimeAnalysis = createSelector(
  [getTrades, getWinTrades, getLossTrades],
  (trades, winTrades, lossTrades) => {
    const analysis = [];

    timeRanges.forEach(({ time, min, max }) => {
      const initialData = { time, total: 0, win: 0, loss: 0, netProfit: 0 };

      const total = trades.reduce((data, trade) => {
        if (trade.entryTime) {
          const entryTime = moment(trade.entryTime.toLowerCase(), "hh:mm a");

          // console.log("\nEntry Time: ", trade.entryTime.toLowerCase(), min, max.format('hh:mm a'),entryTime.diff(min), entryTime.diff(max))

          if (entryTime.diff(min) >= 0 && entryTime.diff(max) < 0) {
            data.total += 1;
            data.netProfit = (
              parseFloat(data.netProfit) + parseFloat(trade.netProfit)
            ).toFixed(2);
          }

          return data;
        }

        return data;
      }, initialData);

      const win = winTrades.reduce((data, trade) => {
        const entryTime = moment(trade.entryTime.toLowerCase(), "hh:mm a");

        if (entryTime.diff(min) >= 0 && entryTime.diff(max) < 0) {
          data.win += 1;
        }

        return data;
      }, total);

      const loss = lossTrades.reduce((data, trade) => {
        const entryTime = moment(trade.entryTime.toLowerCase(), "hh:mm a");

        if (entryTime.diff(min) >= 0 && entryTime.diff(max) < 0) {
          data.loss += 1;
        }

        return data;
      }, win);

      analysis.push(loss);
    });

    return analysis;
  }
);

const getTradesProfit = createSelector([getTrades], (trades) =>
  trades.map((trade, index) => ({
    index,
    date: trade.date,
    netProfit: parseFloat(trade.netProfit),
  }))
);

export const getCumulativeProfit = createSelector(
  [getTradesProfit],
  (trades) => {
    const cumulativeProfits = [];

    trades.reduce(
      (prev, curr) => {
        const next = {
          ...curr,
          netProfit:
            parseFloat((curr.netProfit + prev.netProfit).toFixed(2)) / 2,
        };

        cumulativeProfits.push(next);

        return next;
      },
      { netProfit: 0 }
    );

    return cumulativeProfits;
  }
);

export const getStrategyPerformance = createSelector(
  [getStrategies, getTrades, getWinTrades, getLossTrades],
  (strategies, trades, winTrades, lossTrades) => {
    const analysis = [];

    strategies.forEach((process) => {
      const initialData = { process, total: 0, win: 0, loss: 0 };

      const total = trades.reduce((data, trade) => {
        if (trade.strategies && trade.strategies.includes(process)) {
          data.total += 1;
          data.netProfit = (
            parseFloat(data.netProfit) + parseFloat(trade.netProfit)
          ).toFixed(2);
        }

        return data;
      }, initialData);

      const win = winTrades.reduce((data, trade) => {
        if (trade.strategies && trade.strategies.includes(process)) {
          data.win += 1;
        }

        return data;
      }, total);

      const loss = lossTrades.reduce((data, trade) => {
        if (trade.strategies && trade.strategies.includes(process)) {
          data.loss += 1;
        }

        return data;
      }, win);

      analysis.push(loss);
    });

    return analysis;
  }
);

export const getTradingZoneMap = createSelector(
  [getTradingZones],
  (tradingZones) => {
    const zoneMap = new Map();

    if (!tradingZones || !tradingZones.length) {
      return zoneMap;
    }

    console.log("tradingZones", tradingZones);

    tradingZones.sort(
      (a, b) => a.optionType && a.optionType.localeCompare(b.optionType)
    );

    tradingZones.forEach((element) => {
      let zones = zoneMap.get(element.optionType);

      if (zones) {
        zones.push(element);
      } else {
        zones = [element];
      }

      zoneMap.set(element.optionType, zones);
    });

    return zoneMap;
  }
);

export const getAllTradingZoneMap = createSelector(
  [getAllTradingZones],
  (tradingZones) => {
    const zoneMap = new Map();

    console.log("getAllTradingZoneMap66666", tradingZones);

    if (!tradingZones || !tradingZones.length) {
      return zoneMap;
    }

    tradingZones.sort((a, b) => a.stock && a.stock.localeCompare(b.optionType));

    tradingZones.forEach((element) => {
      let zones = zoneMap.get(element.optionType);

      if (zones) {
        zones.push(element);
      } else {
        zones = [element];
      }

      zoneMap.set(element.optionType, zones);
    });

    return zoneMap;
  }
);

export const getOrderMap = createSelector(
  [getActiveOrders, getPendingOrders],
  (activeOrders, pendingOrders) => {
    const orderMap = new Map();

    if (
      (!activeOrders || !activeOrders.length) &&
      (!pendingOrders || !pendingOrders.length)
    ) {
      return orderMap;
    }

    [...activeOrders, ...pendingOrders].forEach((order) => {
      let orders = orderMap.get(order.status);

      if (orders) {
        orders.push(order);
      } else {
        orders = [order];
      }

      orderMap.set(order.status, orders);
    });

    return orderMap;
  }
);
