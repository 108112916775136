export const getOrder = (trade) => {
  const order = {
    ...trade,
    // 'tradingsymbol': trade.stock,
    transaction_type: trade.action,
    tradingZoneId: trade.id,

    // 'riskReward': trade.riskReward,
    // 'riskPercentage': trade.riskPercentage,
    // 'targetBreak': trade.targetBreak,
    // 'strike': trade.strike,
    // 'optionType': trade.optionType
  };

  if (trade.rider) {
    order.rider = trade.rider;
  }

  return order;
};

export const buttonType = {
  SELL: "danger",
  BUY: "primary",
};
