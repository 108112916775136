import types from './types';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case types.SYNC_FUND:

      return {
        ...state,
        'fund': action.fund
      };
    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        'transactions': action.transactions
      };
    default:
      return state;
  }
}
