import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Card, Statistic, Divider } from 'antd';
import { getInitialCapital,
 getAccountBalance,
 getAccountChangeInPercentage
} from 'redux/modules/banking/selectors';
import { getNetProfit } from 'redux/modules/trading/selectors';

const AccountInfo = () => {
 const initialCapital = useSelector(getInitialCapital);
 const accountBalance = useSelector(getAccountBalance);
 const changePercentage = useSelector(getAccountChangeInPercentage);
 const netProfit = useSelector(getNetProfit);

 return (

  <Card size="small" title="Account Info" style={{ 'marginBottom': '10px' }}>

   <Row justify="space-between" >

    <Col>
     <Statistic
      title="Initial Capital"
      value={initialCapital}
      precision={2}/>

    </Col>
    <Col>
     <Statistic
      title="Account Balance"
      value={accountBalance + netProfit}
      precision={2}/>

    </Col>
    <Col>
     <Statistic
      title="Change %"
      value={changePercentage}
      suffix="%"
      precision={2}
      valueStyle={{ 'color': changePercentage > 0 ? '#3f8600' : '#cf1322',
       'fontWeight': '500' }}/>

    </Col>

   </Row>
   {/* <Divider /> */}
   {/* <Row justify="space-between" style={{marginTop: '10px'}} >
 <Col>
            <Statistic
                  title="Deposit"
                  value={0}
                  precision={2}
                  // valueStyle={{ color: '#cf1322' }}
                  // prefix={<ArrowDownOutlined />}
                />

          </Col>
          <Col>
            <Statistic
                  title="Withdrawal"
                  value={0}
                  precision={2}
                  // valueStyle={{ color: '#cf1322' }}
                  // prefix={<ArrowUpOutlined />}
                  // suffix="%"
                />

          </Col>

        </Row> */}
  </Card>);
};

export default AccountInfo;
