import React from "react";
import { Row, Col, Card } from "antd";

import Performance from "./containers/Performance";
import styles from "./styles.less";
import AccountInfo from "./containers/AccountInfo";
import ExitAnalysis from "../Reports/ExitAnalysis";
import ProcessAnalysis from "../Reports/ProcessAnalysis";
import EntryTimeAnalysis from "../Reports/EntryTimeAnalysis";
import PerformanceChart from "./Charts/Performance";
import AccumulativeProfitChart from "./Charts/AccumulativeProfitChart";
// import API from '../../api';

const DashBoard = () => (
  <>
    <Row className={styles.dashboard} gutter={8}>
      <Col flex="2 1">
        <AccountInfo />
        <Performance />

        {/* <Card size="small" title="Accumulative Return">
      <AccumulativeProfitChart />
     </Card> */}
      </Col>

      {/* <Col
    span={5} style={{ 'marginLeft': '10px',
     'marginBottom': '10px' }}>

    <Card size="small" title="Accumulative Return">
     <AccumulativeProfitChart />
    </Card>
   </Col> */}
      <Col flex="2 1" style={{ marginBottom: "10px" }}>
        <Card
          style={{ marginBottom: "10px", height: "100%" }}
          title="Distribution of Gains and Losses"
        >
          <PerformanceChart />
        </Card>
      </Col>
    </Row>
    <Row className={styles.dashboard} gutter={8}>
      {/* <Col flex="2 1" style={{  border:'1px soild red' }}>
     <Card style={{  height:'100%' }} >
     <EntryTimeAnalysis />
     </Card>
    </Col> */}
      <Col flex="2 1">
        <Card size="small" title="Accumulative Return">
          <AccumulativeProfitChart />
        </Card>
        {/* <ExitAnalysis /> */}

        {/* <ProcessAnalysis  /> */}
      </Col>
    </Row>
  </>
);

export default DashBoard;
