import typesGenerator from 'utils/typesGenerator';

function generateTypes(type) {
  return typesGenerator('banking')(type);
}

const types = {
  ...generateTypes('SET_INITIAL_CAPITAL'),
  'SYNC_FUND': 'SYNC_FUND',
  ...generateTypes('SET_ACCOUNT_BALANCE'),
  ...generateTypes('ADD_TRANSACTION'),
  ...generateTypes('GET_TRANSACTIONS')
};

export default types;
