import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StandardTable from 'components/StandardTable';
import { getStocks as getStocksAction, deleteStock } from 'redux/modules/admin/actions';
import { getStocks } from 'redux/modules/admin/selectors';
import { DeleteTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';

const StockList = () => {
 const dispatch = useDispatch();

 useEffect(() => {
  dispatch(getStocksAction());
 }, [dispatch]);

 const stocks = useSelector(getStocks);

 const columns = [
  {
   'title': 'No',
   'dataIndex': 'index',
   'key': 'index',
   'width': '20px'
  },
  {
   'title': 'Name',
   'dataIndex': 'name',
   'key': 'name'
  },
  {
   'title': 'Instrument Token',
   'dataIndex': 'instrumentToken',
   'key': 'instrumentToken'
  },
  {
   'render': (item)=> (<>
    <Popconfirm
     title="Are you sure delete this stock"
     onConfirm={() => dispatch(deleteStock(item.name))}>
     <a><DeleteTwoTone /></a>
    </Popconfirm>
   </>)
  }

 ];

 return (
  <StandardTable columns={columns} dataSource={stocks} />
 );
};

export default StockList;
