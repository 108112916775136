let Router;

// checks for patterns starting with http, https and even native app launch URLs
const absoluteUrlPattern = /^([a-z]{3,}:)?\/\//;

/**
 * Redirect to a path
 * @param  {String} path Pathname without leading /
 * @param  {Number} scrollPosition vertical scroll position of the page
 * @return {Object}      Router instance for chaining
 */

export function localRedirect(
  path = ''
) {
  const slash = path[0] === '/' ? '' : '/';
  const fullPath = `${slash}${path}`;

  Router.push(fullPath);

  return Router;
}

/**
 * Jumps to a new location. Uses window.location instead of router context if
 * the provided URL was found out to be absolute URL.
 * @param  {String} [path=''] URL to jump to.
 * @return {}           React component that can be inserted to render.
 */
export function redirect(path) {
  if (absoluteUrlPattern.test(path)) {
    window.location.href = path;

    return null;
  }

  return localRedirect(path);
}

export function initializeRouter({ router }) {
  Router = router;
}
