
import { createSelector } from 'reselect';
import { getNetProfit } from 'redux/modules/trading/selectors';

const getfund = state => state.banking.fund || {};

export const getTransactions = state => state.banking.transactions || [];

export const getDeposits = createSelector(
 [getTransactions],
 transactions => transactions.filter(trade => trade.action === 'DEPOSIT') || []
);

export const getWithdrawals = createSelector(
 [getTransactions],
 transactions => transactions.filter(trade => trade.action === 'DEBIT') || []
);

export const getTotalDeposit = createSelector(
 [getDeposits],
 deposits => deposits.reduce((total, deposit) => total + parseFloat(deposit.amount), 0)
);

export const getTotalDebit = createSelector(
 [getWithdrawals],
 deposits => deposits.reduce((total, deposit) => total + parseFloat(deposit.amount), 0)
);

export const getInitialCapital = createSelector(
 [getfund],
 fund => fund.initialCapital
);

// export const getAccountBalance = createSelector(
//   [getfund, getNetProfit, getTotalDeposit, getTotalDebit],
//   (fund, profit, deposit, debit) => parseFloat(fund.initialCapital ||  0) + profit + deposit + debit
// );

export const getAccountChangeInPercentage = createSelector(
 [getfund, getNetProfit],
 (fund, profit) => (((parseFloat(fund.initialCapital) + profit) / fund.initialCapital) * 100) - 100 // ((parseFloat(fund.initialCapital ||  0) + profit) / parseFloat(fund.initialCapital ||  1))* 100
);

export const getAccountBalance = createSelector(
 [getfund],
 fund => fund.accountBalance
);
