
import React from 'react';
import BrokerSettings from './BrokerSettings';
import Strategy from 'pages/Strategies';
import Account from './Account';
import Stocks from './Stocks';

const PAGES = {
  'stocks': <Stocks />,
  'brokerage': <BrokerSettings />,
  'strategy': <Strategy />,
  'account': <Account />

};

const Pages = ({ name }) =>(
  <>
    {PAGES[name]}
  </>
);

export default Pages;
