import typesGenerator from 'utils/typesGenerator';

function generateTypes(type) {
 return typesGenerator('setttings')(type);
}

const types = {
 ...generateTypes('ADD_STOCK'),
 ...generateTypes('UPDATE_STOCK'),
 ...generateTypes('GET_STOCKS'),
 ...generateTypes('GET_STOCK'),
 ...generateTypes('DELETE_STOCK'),
 'SYNC_CREDENTIALS_SETTINGS': 'SYNC_CREDENTIALS_SETTINGS',
 'EDIT_STOCK': 'settings/EDIT_STOCK'
};

export default types;
