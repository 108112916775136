import { all, fork } from 'redux-saga/effects';
import settings from 'redux/modules/settings/sagas';
import banking from 'redux/modules/banking/sagas';
import trading from 'redux/modules/trading/sagas';
import admin from 'redux/modules/admin/sagas';

export default function* rootSaga() {
 yield all([
  fork(settings),
  fork(banking),
  fork(trading),
  fork(admin)
 ]);
}
