// import typesGenerator from 'utils/typesGenerator';

// function generateTypes(type) {
//   return typesGenerator('auth')(type);
// }
// module.exports = {
//   //  ...generateTypes('CREATE_ACCESS_TOKEN'),
//   LOGIN_SUCCESS: 'LOGIN_SUCCESS',
// };

// import typesGenerator from 'utils/typesGenerator';

// function generateTypes(type) {
//   return typesGenerator('trades')(type);
// }

const types = {
  'LOGIN_SUCCESS': 'LOGIN_SUCCESS'

};

export default types;
