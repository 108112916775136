import { useEffect, useState } from "react";
import { database, getUserId } from "service/firebase";
import objectToArray from "utils/objectToArray";

const useOrders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    try {
      database
        .ref("/orders")
        .orderByChild("isActive")
        .equalTo(true)
        // .orderByChild("createdDate")
        // .startAt(moment().format("YYYY-MM-DD"))
        .on("value", (snapshot) => {
          let orderArray = objectToArray(snapshot.val());

          const userId = getUserId();

          if (userId !== "qyxPMfWY3gVDLjzVqLtyp3CxEo12") {
            orderArray = orderArray.filter(
              (order) => order.createdBy === userId
            );
          }

          setOrders(
            orderArray.sort(
              (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
            )
          );
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error", error);
    }
  }, []);

  return orders;
};

export default useOrders;
