import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StandardTable from 'components/StandardTable';
import { getOrderMap, getAllTradingZoneMap, hasZoneWishList } from 'redux/modules/trading/selectors';
import { placeLimitOrder,
 deleteTradeZone,
 placeSLOrder,
 addZoneWishList,
 getAllTradingZones
} from 'redux/modules/trading/actions';
import { FileAddOutlined, DeleteOutlined, HeartTwoTone, UnderlineOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
 Button, Modal, Popconfirm, Tabs
} from 'antd';
import { getOrder, buttonType } from './utils';

const { TabPane } = Tabs;

const AllTradingZones = () => {
 const zoneMap = useSelector(getAllTradingZoneMap);

 const dispatch = useDispatch();

 useEffect(() => {
  dispatch(getAllTradingZones());
 }, [dispatch]);

 const columns = [
  {
   'title': 'No',
   'width': '20px',
   'render': (text, row, index) => index + 1
  },
  {
   'title': 'Date',
   'key': 'date',
   'dataIndex': 'date'
   //  'sorter': (a, b) => a.action.localeCompare(b.date)

  //  'render': (text, row, index) => index + 1
  },
  {
   'title': 'User',
   'dataIndex': 'userName',
   'key': 'userName',
   // sortDirections: 'ascend',
   // sorter: (a, b) => a && a.stock && a.stock.localeCompare(b.stock),
   'defaultSortOrder': 'ascend',
   'sorter': (a, b) => a.action.localeCompare(b.userName)

  },
  // {
  //  'title': 'Stock',
  //  'dataIndex': 'stock',
  //  'key': 'stock',
  //  // sortDirections: 'ascend',
  //  // sorter: (a, b) => a && a.stock && a.stock.localeCompare(b.stock),
  //  'defaultSortOrder': 'ascend'

  // },

  {
   'title': 'Action',
   'dataIndex': 'action',
   'key': 'action',
   'sorter': (a, b) => a.action.localeCompare(b.action),
   'render': (action) => (<span style={{ 'fontWeight': '600',
    'color': action === 'BUY' ? ' #90ee90' : 'red' }}>{action}</span>)
  },
  // {
  //  'title': 'Quantity',
  //  'dataIndex': 'quantity',
  //  'key': 'quantity'
  // },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'key': 'price',
   'sorter': (a, b) => parseInt(a.price) - parseInt(b.price),
   'render': (price) => (<span style={{ 'fontWeight': '600',
    'color': '#FFF' }}>{price}</span>)

  },

  {
   'title': 'Stop Loss',
   'dataIndex': 'stopLoss',
   'key': 'stopLoss'
  },
  // {
  //  'title': 'Trailing SL',
  //  'dataIndex': 'trailingStopLoss',
  //  'key': 'trailingStopLoss'
  // },
  {
   'title': 'Target',
   'dataIndex': 'target',
   'key': 'target'
  },
  // {
  //  'title': 'Breakeven',
  //  'dataIndex': 'breakeven',
  //  'key': 'breakeven'
  // },
  {
   'title': 'Risk',
   'dataIndex': 'risk',
   'key': 'risk',
   'render': (risk) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{risk}</span>),
   'align': 'center'
  },
  {
   'title': '% Risk',
   'dataIndex': 'riskPercentage',
   'key': 'riskPercentage',
   'render': (riskPercentage) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{riskPercentage}%</span>),
   'align': 'center'
  },
  {
   'title': 'Planned R:R',
   'dataIndex': 'riskReward',
   'key': 'riskReward',
   'align': 'center',
   'render': (riskReward) => <span style={{ 'fontWeight': '500' }}>1 : {riskReward}</span>
  }

  // {
  //  'title': 'Status',
  //  'dataIndex': 'orderStatus',
  //  'key': 'orderStatus',
  //  'sorter': (a, b) => a && a.orderStatus && a.orderStatus.localeCompare(b.orderStatus),
  //  'defaultSortOrder': 'ascend'
  // },
  // {
  //  'title': 'Time Frame',
  //  'dataIndex': 'timeFrame',
  //  'key': 'timeFrame',
  //  'sorter': (a, b) => a && a.timeFrame && a.timeFrame.localeCompare(b.timeFrame),
  //  'defaultSortOrder': 'ascend'
  // },
  // {
  //  'align': 'center',
  //  'render': (trade)=>
  //  //  trade.orderStatus === 'PENDING' &&
  //   trade.orderStatus !== 'OPEN' &&
  //        <>
  //         <Button style={{ 'fontWeight': '500' }} onClick={() => dispatch(placeLimitOrder(getOrder(trade)))} type={buttonType[trade.action]}>
  //          {trade.action}
  //         </Button>
  //         <Button
  //          style={{ 'fontWeight': '500',
  //           'marginLeft': 20 }} onClick={() => dispatch(placeSLOrder(getOrder(trade)))} type={buttonType[trade.action]}>
  //          {trade.action} SL
  //         </Button>
  //        </>

  // },
  // {
  //  'align': 'center',
  //  'render': (trade)=>
  //  //  trade.orderStatus === 'PENDING' &&

  //   trade.orderStatus !== 'OPEN' &&
  //        <>
  //         <Button
  //          shape="circle"
  //          icon={<HeartTwoTone />}
  //          style={{ 'fontWeight': '500' }}
  //          onClick={() => dispatch(addZoneWishList(trade))} type="danger" />
  //        </>

  // },
  // {
  //  'render': (trade)=> (<Popconfirm
  //   title="Are you sure delete this trade zone"
  //   onConfirm={() => dispatch(deleteTradeZone(trade.id))}>
  //   <a><DeleteOutlined /></a>
  //  </Popconfirm>)
  // }
 ];

 const operations = (<Button
  type="primary" onClick={() => {
   setModalVisible(true);
  }} icon={<FileAddOutlined />}>
            New Zone
 </Button>);

 const zoneTabs = [];

 for (const [stock, zones] of zoneMap.entries()) {
  console.log('zones', zones);
  zoneTabs.push(<TabPane
   tab={
    <span>
     {stock}
    </span>
   } key={stock}>
   <StandardTable columns={columns} dataSource={zones} style={{ 'marginBottom': '20px' }}/>
  </TabPane>);
 }

 return (
  <Tabs tabBarExtraContent={operations}>
   {
    zoneTabs
   }
  </Tabs>

 );
};

export default AllTradingZones;
