import axios from 'axios';
import { getIdToken } from '../service/firebase';
// export const baseURL = 'http://localhost:5001/durga-nse/us-central1/api/';
export const baseURL = 'http://localhost:8000/';

const requestInstance = axios.create({
 baseURL
 // withCredentials: true,
});

requestInstance.interceptors.response.use((response) => {
 if (response.data.error_type) {
  throw response.data;
 }

 // Return success data
 return response.data;
}, (error) => {
 console.log('error', error);
 let resp = {
  'message': 'Unknown error',
  'error_type': 'GeneralException',
  'data': null
 };

 console.log('error.request', error.request, error.message);

 if (error.response) {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response.data && error.response.data.error_type) {
   if (error.response.data.error_type === 'TokenException') {
    // self.session_expiry_hook();
    console.log('TokenException', error);
   }

   resp = error.response.data;
  } else {
   resp.error_type = 'NetworkException';
   resp.message = error.response.statusText;
  }
 } else if (error.request) {
  // The request was made but no response was received
  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  // http.ClientRequest in node.js
  resp.error_type = 'NetworkException';
  resp.message = 'No response from server with error code: ' + error.code;
 } else if (error.message) {
  resp = error;
 }

 return Promise.reject(resp);
});

requestInstance.interceptors.request.use(async config => {
 config.headers.Authorization = 'Bearer ' + await getIdToken();

 return config;
}, (error) => Promise.reject(error));

const CloudApi = (() => {
 const init = () => {

 };
 const placeCoverOrder = () => requestInstance.get('generateAccessToken');
 const generateAccessToken = (requestToken) => requestInstance.post('generateAccessToken', { requestToken });
 const placeOrder = (order, variety) => requestInstance.post('placeOrder', { order,
  variety });
 const updateOrder = (order) => requestInstance.post('updateOrder', { order });

 return {
  init,
  placeCoverOrder,
  generateAccessToken,
  placeOrder,
  updateOrder
 };
})();

export default CloudApi;

