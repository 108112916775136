import { useEffect, useState } from "react";
import { database,getUserId } from "service/firebase";
import objectToArray from "utils/objectToArray";

const useTrades = (table) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    try {
      database
        .ref("/" + table)
        .orderByChild("createdDate")

        .on("value", (snapshot) => {
          let orderArray = objectToArray(snapshot.val());
          const userId = getUserId();

          if (userId !== 'qyxPMfWY3gVDLjzVqLtyp3CxEo12') {
            orderArray = orderArray.filter(order => order.createdBy === userId);
          }

          setOrders(
            orderArray.sort(
              (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
            )
          );
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error", error);
    }
  }, [table]);

  return orders;
};

export default useTrades;
