import React from 'react';
import { useSelector } from 'react-redux';

import {
 Chart,
 Line,
 Point,
 Axis,
 Geom
} from 'bizcharts';
import { getCumulativeProfit } from 'redux/modules/trading/selectors';

// const scale = { netProfit: { min: -15000 }}

const AccumulativeProfitChart = () => {
 const chartData = useSelector(getCumulativeProfit);

 return (
  <Chart
   autoFit
   height={300}
   padding={[10, 20, 50, 40]}
   data={chartData}>
   <Axis name="index" visible={false} />
   {/* <Line position="index*netProfit"  />
    <Point position="index*netProfit" /> */}
   <Geom type="line" position="index*netProfit" shape="smooth" />
  </Chart>
 );
};

export default AccumulativeProfitChart;
