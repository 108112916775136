import types from './types';

export const addStock = stock => ({
 'type': types.ADD_STOCK_REQUEST,
 stock
});

export const getStocks = () => ({
 'type': types.GET_STOCKS_REQUEST
});

export const deleteStock = id => ({
 'type': types.DELETE_STOCK_REQUEST,
 id
});

export const getStocksSuccess = stockResult => ({
 'type': types.GET_STOCKS_SUCCESS,
 stockResult
});
