import React, { useState } from 'react';
import StockForm from './StockForm';
import StockList from './StockList';

const Strategy = () => (
  <>
    <StockForm />
    <StockList />
  </>
);

export default Strategy;
