import types from './types';
import OrderVariety from '../../../constants/orderVariety';

export const addTrade = trade => ({
 'type': types.ADD_TRADE_REQUEST,
 trade
});

export const updateTrade = trade => ({
 'type': types.UPDATE_TRADE_REQUEST,
 trade
});

export const syncTrades = trades => ({
 'type': types.SYNC_TRADES,
 trades
});

export const deleteTrade = id => ({
 'type': types.DELETE_TRADE_REQUEST,
 id
});

export const addStrategy = strategy => ({
 'type': types.ADD_STRATEGY_REQUEST,
 strategy
});

export const deleteStrategy = id => ({
 'type': types.DELETE_STRATEGY_REQUEST,
 id
});

export const getStrategies = () => ({
 'type': types.GET_STRATEGIES_REQUEST
});

export const getStrategiesSuccess = strategies => ({
 'type': types.GET_STRATEGIES_SUCCESS,
 strategies
});

export const addTradingZone = tradingZone => ({
 'type': types.ADD_TRADING_ZONE_REQUEST,
 tradingZone
});

export const updateTradingZone = tradingZone => ({
 'type': types.UPDATE_TRADING_ZONE_REQUEST,
 tradingZone
});

export const deleteTradeZone = id => ({
 'type': types.DELETE_TRADING_ZONE_REQUEST,
 id
});

export const getTradingZones = () => ({
 'type': types.GET_TRADING_ZONES_REQUEST
});

export const getAllTradingZones = () => ({
 'type': types.GET_ALL_TRADING_ZONES_REQUEST
});

export const getTradingZonesSuccess = tradingZones => ({
 'type': types.GET_TRADING_ZONES_SUCCESS,
 tradingZones
});

export const getAllTradingZonesSuccess = tradingZones => ({
 'type': types.GET_ALL_TRADING_ZONES_SUCCESS,
 tradingZones
});

export const placeOrder = (order, variety) => ({
 'type': types.PLACE_ORDER_REQUEST,
 order,
 variety
});

export const placeRegularOrder = (order) => placeOrder({
 ...order,
 'product': 'MIS',
 'exchange': 'NFO',
 'validity': 'DAY',
 'variety': OrderVariety.REGULAR
}, OrderVariety.REGULAR);

export const placeLimitOrder = (order) => placeRegularOrder({ ...order,
 'order_type': 'LIMIT' });

export const placeSLOrder = (order) => placeRegularOrder({ ...order,
 'order_type': 'SL',
 'trigger_price': order.price });

export const syncOrderSuccess = orders => ({
 'type': types.SYNC_ORDER_SUCCESS,
 orders
});

export const updateOrder = (order) => ({
 'type': types.UPDATE_ORDER_REQUEST,
 order
});

export const createAccessToken = (requestToken) => ({
 'type': types.CREATE_ACCESS_TOKEN_REQUEST,
 requestToken
});

export const cancelOrder = (orderId) => ({
 'type': types.CANCEL_ORDER_REQUEST,
 orderId
});

export const syncZoneWishListSuccess = zoneWishList => ({
 'type': types.SYNC_ZONE_WISHLIST_SUCCESS,
 zoneWishList
});

export const syncPendingOrdersSuccess = pendingOrders => ({
 'type': types.SYNC_PENDING_ORDERS_SUCCESS,
 pendingOrders
});

export const addZoneWishList = zone => ({
 'type': types.ADD_ZONE_WISHLIST_REQUEST,
 zone
});

export const deleteZoneWishList = id => ({
 'type': types.DELETE_ZONE_WISHLIST_REQUEST,
 id
});

export const deletePendingAction = id => ({
 'type': types.DELETE_PENDING_ACTION_REQUEST,
 id
});

export const exitOrder = (orderId) => ({
 'type': types.EXIT_ORDER_REQUEST,
 orderId
});

