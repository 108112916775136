import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StandardTable from 'components/StandardTable';
import { getClosedTrades } from 'redux/modules/trading/selectors';
import moment from 'moment';

const ClosedTrades = () => {
 const trades = useSelector(getClosedTrades);

 const columns = [
  {
   'title': 'No',
   // dataIndex: 'index',
   // key: 'index',
   'width': '50px',
   'render': (_, __, index) => index + 1
  },

  {
   'title': 'Date',
   'dataIndex': 'date',
   'width': '90px',

   'key': 'date',
   'render': (createdDate) => moment(createdDate, 'YYYYMMDD').format('YYYY-MM-DD')

  },
  {
   'title': 'Stock',
   'dataIndex': 'stock',
   'key': 'stock',
   'width': '105px'

  },
  {
   'title': 'Action',
   'dataIndex': 'transactionType',
   'key': 'transactionType',
   'width': '80px'

  },
  {
   'title': 'QTY',
   'dataIndex': 'quantity',
   'width': '60px',
   'key': 'quantity'
  },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'width': '65px',
   'key': 'price'
  },
  {
   'title': 'Exit',
   'dataIndex': 'exitPrice',
   'width': '65px',
   'key': 'exitPrice'
  },
  {
   'title': 'Net P/L',
   'width': '75px',
   'dataIndex': 'netProfit',
   'key': 'netProfit',
   'render': (netProfit) => {
    const color = netProfit > 0 ? '#0AC92B' : 'red';

    return (<span style={{ 'fontWeight': '500',
     color }}>{netProfit}</span>);
   }
  },
  {
   'title': 'Change %',
   'dataIndex': 'accountChange',
   'key': 'accountChange',
   'width': '75px'

  },
  {
   'title': '% Risk',
   'dataIndex': 'riskPercentage',
   'width': '75px',

   'key': 'riskPercentage',
   'render': (riskPercentage) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{riskPercentage}%</span>),
   'align': 'center'
  },
  {
   'title': 'R:R',
   'width': '65px',
   'dataIndex': 'riskReward',
   'key': 'riskReward',
   'align': 'center',
   'render': (riskReward) => <span style={{ 'fontWeight': '500' }}>1 : {riskReward}</span>
  },

  {
   'title': 'Exit Reason',
   'dataIndex': 'exitReason',
   'key': 'exitReason',
   'ellipsis': true,
   'width': '130px'

  }
  //  {
  //   title: 'Psycology',
  //   dataIndex: 'psychology',
  //    width: '120px',
  //   key: 'psychology',
  //   render: (psychology) => {
  //     return psychology ? psychology.join(',') : ''
  //   },
  //    ellipsis: true,
  // },
  // {
  //   title: 'Emotion',
  //    width: '120px',
  //   dataIndex: 'emotion',
  //   key: 'emotion',
  //     render: (emotion) => {
  //     return emotion ? emotion.join(',') : ''
  //   },
  //    ellipsis: true,
  // },
  // {
  //   title: 'Process',
  //    width: '120px',
  //   dataIndex: 'management',
  //   key: 'management',
  //   render: (management) => {
  //     return management ? management.join(',') : ''
  //   },
  //    ellipsis: true,

  // },
  // {
  //   title: 'Notes',
  //    width: '200px',
  //   dataIndex: 'notes',
  //   key: 'notes',
  //   ellipsis: true,
  // },
  //  {

  //   render: (trade)=> {
  //   return (<Popconfirm
  //     title="Are you sure delete this trade"
  //     onConfirm={() => dispatch(deleteTrade(trade.id))}>
  //     <a><DeleteOutlined /></a>
  //   </Popconfirm>
  //     )
  //   }
  // },
 ];

 return (
  <>
   <StandardTable columns={columns} dataSource={trades} />
  </>
 );
};

export default ClosedTrades;
