import React from 'react';
import NewStrategy from './NewStrategy';
import StrategyList from './StrategyList';

const Strategy = () => (
  <>
    <NewStrategy />
    <StrategyList />
  </>
);

export default Strategy;
