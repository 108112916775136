import React from 'react';

export const Banner30DataSource = {
 'wrapper': { 'className': 'banner3' },
 'textWrapper': {
  'className': 'banner3-text-wrapper',
  'children': [
   {
    'name': 'nameEn',
    'className': 'banner3-name-en',
    'children': (
     <span>
      <p>Algo Trading App To Boost Your Performance</p>
     </span>
    )
   },
   {
    'name': 'slogan',
    'className': 'banner3-slogan',
    'children': 'Spinta Trade',
    'texty': true
   },
   {
    'name': 'name',
    'className': 'banner3-name',
    'children': (
     <span>
      <p>The ultimate trading App</p>
     </span>
    )
   },
   {
    'name': 'button',
    'className': 'banner3-button kbw8rq6yaf9-editor_css',
    'children': (
     <span>
      <span>
       <span>
        <p>Sign In</p>
       </span>
      </span>
     </span>
    )
   }
   // {
   //   name: 'time',
   //   className: 'banner3-time',
   //   children: (
   //     <span>
   //       <p>2018.01.06 / 中国·杭州</p>
   //     </span>
   //   ),
   // },
  ]
 }
};
