import {
 all, fork, takeEvery, call, takeLatest, take, cancel, put
} from 'redux-saga/effects';
import dbService from 'service/dbService';
import userTypes from 'redux/modules/user/types';
import types from './types';
import { syncFund, getTransactionsSuccess } from './actions';
import objectToArray from 'utils/objectToArray';

const transform = (fund) => {
 fund.value.transactions = fund.value ? objectToArray(fund.value.transactions) : [];

 return fund.value;
};

function* syncFundSaga() {
 const task = yield fork(
  dbService.sync,
  'funds',
  { 'successActionCreator': syncFund,
   transform }
 );

 // Wait for the logout action, then stop sync
 yield take('LOGOUT');
 yield cancel(task);
}

function* setInitialFundSaga(action) {
 const { initialCapital } = action;

 const task = yield call(
  dbService.patch,
  'funds',
  {
   initialCapital
  }
 );

 // Wait for the logout action, then stop sync
 yield take('LOGOUT');
 yield cancel(task);
}

function* setAccountBalanceSaga(action) {
 const { accountBalance } = action;

 const task = yield call(
  dbService.patch,
  'funds',
  {
   accountBalance
  }
 );

 // Wait for the logout action, then stop sync
 yield take('LOGOUT');
 yield cancel(task);
}

function* addTransactionSaga(action) {
 try {
  const { transaction } = action;

  yield call(
   dbService.create,
   'funds/transactions',
   transaction
  );

  yield call(getTransactionsSaga);
 } catch (e) {
  console.log(e);
 }
}

function* getTransactionsSaga() {
 try {
  const transactions = yield call(
   dbService.read,
   'funds/transactions'
  );

  yield put(getTransactionsSuccess(objectToArray(transactions)));
 } catch (e) {
  console.log(e);
 }
}

export default function* settingsSaga() {
 yield all([
  yield takeLatest(userTypes.LOGIN_SUCCESS, syncFundSaga),
  yield takeLatest(types.SET_INITIAL_CAPITAL_REQUEST, setInitialFundSaga),
  yield takeLatest(types.SET_ACCOUNT_BALANCE_REQUEST, setAccountBalanceSaga),
  yield takeLatest(types.ADD_TRANSACTION_REQUEST, addTransactionSaga)
  // yield takeLatest(types.GET_TRANSACTIONS_REQUEST, getTransactionsSaga),
 ]);
}

