import types from './types';

export default function reducer(state = {}, action = {}) {
 switch (action.type) {
  case types.GET_STOCKS_SUCCESS:
   return {
    ...state,
    'stocks': action.stockResult.stocks,
    'instruments': action.stockResult.instruments
   };

  default:
   return state;
 }
}
