import types from './types';

const initialState = {
 'loading': false,
 'loggedIn': false
};

export default function loginReducer(state = initialState, action = {}) {
 switch (action.type) {
  case types.LOGIN_REQUEST:
  case types.LOGOUT_REQUEST:
   return {
    ...state,
    'loading': true
   };
  case types.LOGIN_SUCCESS:

   return {
    ...state,
    'loading': false,
    'loggedIn': true,
    ...action.user
   };

  default:
   return state;
 }
}
