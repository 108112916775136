export default [
 {
  'instrument_token': 12345602,
  'exchange_token': 48225,
  'tradingsymbol': 'NIFTY2250514350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12348162,
  'exchange_token': 48235,
  'tradingsymbol': 'NIFTY2250514350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12348418,
  'exchange_token': 48236,
  'tradingsymbol': 'NIFTY2250514400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12348674,
  'exchange_token': 48237,
  'tradingsymbol': 'NIFTY2250514400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12349442,
  'exchange_token': 48240,
  'tradingsymbol': 'NIFTY2250514450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12349698,
  'exchange_token': 48241,
  'tradingsymbol': 'NIFTY2250514450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12349954,
  'exchange_token': 48242,
  'tradingsymbol': 'NIFTY2250514500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12350210,
  'exchange_token': 48243,
  'tradingsymbol': 'NIFTY2250514500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12350466,
  'exchange_token': 48244,
  'tradingsymbol': 'NIFTY2250514550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12362498,
  'exchange_token': 48291,
  'tradingsymbol': 'NIFTY2250514550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12362754,
  'exchange_token': 48292,
  'tradingsymbol': 'NIFTY2250514600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12363010,
  'exchange_token': 48293,
  'tradingsymbol': 'NIFTY2250514600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12363266,
  'exchange_token': 48294,
  'tradingsymbol': 'NIFTY2250514650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12363522,
  'exchange_token': 48295,
  'tradingsymbol': 'NIFTY2250514650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12363778,
  'exchange_token': 48296,
  'tradingsymbol': 'NIFTY2250514700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12368642,
  'exchange_token': 48315,
  'tradingsymbol': 'NIFTY2250514700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11461634,
  'exchange_token': 44772,
  'tradingsymbol': 'NIFTY2250514750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11461890,
  'exchange_token': 44773,
  'tradingsymbol': 'NIFTY2250514750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11462146,
  'exchange_token': 44774,
  'tradingsymbol': 'NIFTY2250514800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11462402,
  'exchange_token': 44775,
  'tradingsymbol': 'NIFTY2250514800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11462658,
  'exchange_token': 44776,
  'tradingsymbol': 'NIFTY2250514850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11462914,
  'exchange_token': 44777,
  'tradingsymbol': 'NIFTY2250514850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11463170,
  'exchange_token': 44778,
  'tradingsymbol': 'NIFTY2250514900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11463426,
  'exchange_token': 44779,
  'tradingsymbol': 'NIFTY2250514900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11463682,
  'exchange_token': 44780,
  'tradingsymbol': 'NIFTY2250514950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11463938,
  'exchange_token': 44781,
  'tradingsymbol': 'NIFTY2250514950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 14950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10837762,
  'exchange_token': 42335,
  'tradingsymbol': 'NIFTY2250515000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10839810,
  'exchange_token': 42343,
  'tradingsymbol': 'NIFTY2250515000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10840578,
  'exchange_token': 42346,
  'tradingsymbol': 'NIFTY2250515050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10842882,
  'exchange_token': 42355,
  'tradingsymbol': 'NIFTY2250515050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10843138,
  'exchange_token': 42356,
  'tradingsymbol': 'NIFTY2250515100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10843394,
  'exchange_token': 42357,
  'tradingsymbol': 'NIFTY2250515100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10844162,
  'exchange_token': 42360,
  'tradingsymbol': 'NIFTY2250515150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10845186,
  'exchange_token': 42364,
  'tradingsymbol': 'NIFTY2250515150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10845442,
  'exchange_token': 42365,
  'tradingsymbol': 'NIFTY2250515200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10845698,
  'exchange_token': 42366,
  'tradingsymbol': 'NIFTY2250515200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10846722,
  'exchange_token': 42370,
  'tradingsymbol': 'NIFTY2250515250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10846978,
  'exchange_token': 42371,
  'tradingsymbol': 'NIFTY2250515250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10847234,
  'exchange_token': 42372,
  'tradingsymbol': 'NIFTY2250515300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10847490,
  'exchange_token': 42373,
  'tradingsymbol': 'NIFTY2250515300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10847746,
  'exchange_token': 42374,
  'tradingsymbol': 'NIFTY2250515350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10848002,
  'exchange_token': 42375,
  'tradingsymbol': 'NIFTY2250515350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10849282,
  'exchange_token': 42380,
  'tradingsymbol': 'NIFTY2250515400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10849538,
  'exchange_token': 42381,
  'tradingsymbol': 'NIFTY2250515400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10849794,
  'exchange_token': 42382,
  'tradingsymbol': 'NIFTY2250515450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10851074,
  'exchange_token': 42387,
  'tradingsymbol': 'NIFTY2250515450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10851842,
  'exchange_token': 42390,
  'tradingsymbol': 'NIFTY2250515500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10852098,
  'exchange_token': 42391,
  'tradingsymbol': 'NIFTY2250515500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10852354,
  'exchange_token': 42392,
  'tradingsymbol': 'NIFTY2250515550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10852610,
  'exchange_token': 42393,
  'tradingsymbol': 'NIFTY2250515550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10852866,
  'exchange_token': 42394,
  'tradingsymbol': 'NIFTY2250515600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10853122,
  'exchange_token': 42395,
  'tradingsymbol': 'NIFTY2250515600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10854402,
  'exchange_token': 42400,
  'tradingsymbol': 'NIFTY2250515650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10856194,
  'exchange_token': 42407,
  'tradingsymbol': 'NIFTY2250515650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10856450,
  'exchange_token': 42408,
  'tradingsymbol': 'NIFTY2250515700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10859522,
  'exchange_token': 42420,
  'tradingsymbol': 'NIFTY2250515700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10859778,
  'exchange_token': 42421,
  'tradingsymbol': 'NIFTY2250515750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10860034,
  'exchange_token': 42422,
  'tradingsymbol': 'NIFTY2250515750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10860290,
  'exchange_token': 42423,
  'tradingsymbol': 'NIFTY2250515800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10865154,
  'exchange_token': 42442,
  'tradingsymbol': 'NIFTY2250515800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10865666,
  'exchange_token': 42444,
  'tradingsymbol': 'NIFTY2250515850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10865922,
  'exchange_token': 42445,
  'tradingsymbol': 'NIFTY2250515850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10866178,
  'exchange_token': 42446,
  'tradingsymbol': 'NIFTY2250515900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10866434,
  'exchange_token': 42447,
  'tradingsymbol': 'NIFTY2250515900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10866690,
  'exchange_token': 42448,
  'tradingsymbol': 'NIFTY2250515950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10866946,
  'exchange_token': 42449,
  'tradingsymbol': 'NIFTY2250515950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 15950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10867202,
  'exchange_token': 42450,
  'tradingsymbol': 'NIFTY2250516000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10867458,
  'exchange_token': 42451,
  'tradingsymbol': 'NIFTY2250516000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10869762,
  'exchange_token': 42460,
  'tradingsymbol': 'NIFTY2250516050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10870018,
  'exchange_token': 42461,
  'tradingsymbol': 'NIFTY2250516050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10870274,
  'exchange_token': 42462,
  'tradingsymbol': 'NIFTY2250516100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10870530,
  'exchange_token': 42463,
  'tradingsymbol': 'NIFTY2250516100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10871298,
  'exchange_token': 42466,
  'tradingsymbol': 'NIFTY2250516150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10872066,
  'exchange_token': 42469,
  'tradingsymbol': 'NIFTY2250516150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10872322,
  'exchange_token': 42470,
  'tradingsymbol': 'NIFTY2250516200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10874114,
  'exchange_token': 42477,
  'tradingsymbol': 'NIFTY2250516200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10876674,
  'exchange_token': 42487,
  'tradingsymbol': 'NIFTY2250516250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10876930,
  'exchange_token': 42488,
  'tradingsymbol': 'NIFTY2250516250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10877186,
  'exchange_token': 42489,
  'tradingsymbol': 'NIFTY2250516300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10877442,
  'exchange_token': 42490,
  'tradingsymbol': 'NIFTY2250516300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10877698,
  'exchange_token': 42491,
  'tradingsymbol': 'NIFTY2250516350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10877954,
  'exchange_token': 42492,
  'tradingsymbol': 'NIFTY2250516350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10878210,
  'exchange_token': 42493,
  'tradingsymbol': 'NIFTY2250516400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10878466,
  'exchange_token': 42494,
  'tradingsymbol': 'NIFTY2250516400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10878722,
  'exchange_token': 42495,
  'tradingsymbol': 'NIFTY2250516450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10879490,
  'exchange_token': 42498,
  'tradingsymbol': 'NIFTY2250516450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10879746,
  'exchange_token': 42499,
  'tradingsymbol': 'NIFTY2250516500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10880002,
  'exchange_token': 42500,
  'tradingsymbol': 'NIFTY2250516500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10880258,
  'exchange_token': 42501,
  'tradingsymbol': 'NIFTY2250516550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10880514,
  'exchange_token': 42502,
  'tradingsymbol': 'NIFTY2250516550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10880770,
  'exchange_token': 42503,
  'tradingsymbol': 'NIFTY2250516600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10881026,
  'exchange_token': 42504,
  'tradingsymbol': 'NIFTY2250516600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10881282,
  'exchange_token': 42505,
  'tradingsymbol': 'NIFTY2250516650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10881538,
  'exchange_token': 42506,
  'tradingsymbol': 'NIFTY2250516650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10881794,
  'exchange_token': 42507,
  'tradingsymbol': 'NIFTY2250516700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10882050,
  'exchange_token': 42508,
  'tradingsymbol': 'NIFTY2250516700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10882306,
  'exchange_token': 42509,
  'tradingsymbol': 'NIFTY2250516750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10882562,
  'exchange_token': 42510,
  'tradingsymbol': 'NIFTY2250516750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10882818,
  'exchange_token': 42511,
  'tradingsymbol': 'NIFTY2250516800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10883330,
  'exchange_token': 42513,
  'tradingsymbol': 'NIFTY2250516800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10883842,
  'exchange_token': 42515,
  'tradingsymbol': 'NIFTY2250516850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10884610,
  'exchange_token': 42518,
  'tradingsymbol': 'NIFTY2250516850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10884866,
  'exchange_token': 42519,
  'tradingsymbol': 'NIFTY2250516900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10885378,
  'exchange_token': 42521,
  'tradingsymbol': 'NIFTY2250516900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10886146,
  'exchange_token': 42524,
  'tradingsymbol': 'NIFTY2250516950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10886914,
  'exchange_token': 42527,
  'tradingsymbol': 'NIFTY2250516950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 16950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10887426,
  'exchange_token': 42529,
  'tradingsymbol': 'NIFTY2250517000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10887682,
  'exchange_token': 42530,
  'tradingsymbol': 'NIFTY2250517000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10888194,
  'exchange_token': 42532,
  'tradingsymbol': 'NIFTY2250517050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10888450,
  'exchange_token': 42533,
  'tradingsymbol': 'NIFTY2250517050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10888706,
  'exchange_token': 42534,
  'tradingsymbol': 'NIFTY2250517100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10888962,
  'exchange_token': 42535,
  'tradingsymbol': 'NIFTY2250517100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10889218,
  'exchange_token': 42536,
  'tradingsymbol': 'NIFTY2250517150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10891010,
  'exchange_token': 42543,
  'tradingsymbol': 'NIFTY2250517150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10891266,
  'exchange_token': 42544,
  'tradingsymbol': 'NIFTY2250517200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10892802,
  'exchange_token': 42550,
  'tradingsymbol': 'NIFTY2250517200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10894850,
  'exchange_token': 42558,
  'tradingsymbol': 'NIFTY2250517250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10895106,
  'exchange_token': 42559,
  'tradingsymbol': 'NIFTY2250517250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10896130,
  'exchange_token': 42563,
  'tradingsymbol': 'NIFTY2250517300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10896386,
  'exchange_token': 42564,
  'tradingsymbol': 'NIFTY2250517300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10897154,
  'exchange_token': 42567,
  'tradingsymbol': 'NIFTY2250517350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10897922,
  'exchange_token': 42570,
  'tradingsymbol': 'NIFTY2250517350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10898178,
  'exchange_token': 42571,
  'tradingsymbol': 'NIFTY2250517400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10898434,
  'exchange_token': 42572,
  'tradingsymbol': 'NIFTY2250517400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10898690,
  'exchange_token': 42573,
  'tradingsymbol': 'NIFTY2250517450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10898946,
  'exchange_token': 42574,
  'tradingsymbol': 'NIFTY2250517450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10899202,
  'exchange_token': 42575,
  'tradingsymbol': 'NIFTY2250517500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10899458,
  'exchange_token': 42576,
  'tradingsymbol': 'NIFTY2250517500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10899714,
  'exchange_token': 42577,
  'tradingsymbol': 'NIFTY2250517550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10902018,
  'exchange_token': 42586,
  'tradingsymbol': 'NIFTY2250517550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10902274,
  'exchange_token': 42587,
  'tradingsymbol': 'NIFTY2250517600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10902530,
  'exchange_token': 42588,
  'tradingsymbol': 'NIFTY2250517600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10902786,
  'exchange_token': 42589,
  'tradingsymbol': 'NIFTY2250517650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10906114,
  'exchange_token': 42602,
  'tradingsymbol': 'NIFTY2250517650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10906370,
  'exchange_token': 42603,
  'tradingsymbol': 'NIFTY2250517700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10906626,
  'exchange_token': 42604,
  'tradingsymbol': 'NIFTY2250517700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10906882,
  'exchange_token': 42605,
  'tradingsymbol': 'NIFTY2250517750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10907650,
  'exchange_token': 42608,
  'tradingsymbol': 'NIFTY2250517750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10907906,
  'exchange_token': 42609,
  'tradingsymbol': 'NIFTY2250517800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10909954,
  'exchange_token': 42617,
  'tradingsymbol': 'NIFTY2250517800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10910210,
  'exchange_token': 42618,
  'tradingsymbol': 'NIFTY2250517850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10910978,
  'exchange_token': 42621,
  'tradingsymbol': 'NIFTY2250517850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10911234,
  'exchange_token': 42622,
  'tradingsymbol': 'NIFTY2250517900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10914050,
  'exchange_token': 42633,
  'tradingsymbol': 'NIFTY2250517900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10915330,
  'exchange_token': 42638,
  'tradingsymbol': 'NIFTY2250517950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10915586,
  'exchange_token': 42639,
  'tradingsymbol': 'NIFTY2250517950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 17950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10915842,
  'exchange_token': 42640,
  'tradingsymbol': 'NIFTY2250518000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10916098,
  'exchange_token': 42641,
  'tradingsymbol': 'NIFTY2250518000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13590530,
  'exchange_token': 53088,
  'tradingsymbol': 'NIFTY2250518050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13590786,
  'exchange_token': 53089,
  'tradingsymbol': 'NIFTY2250518050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13591042,
  'exchange_token': 53090,
  'tradingsymbol': 'NIFTY2250518100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13591298,
  'exchange_token': 53091,
  'tradingsymbol': 'NIFTY2250518100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 8961026,
  'exchange_token': 35004,
  'tradingsymbol': 'NIFTY2250518150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 8961282,
  'exchange_token': 35005,
  'tradingsymbol': 'NIFTY2250518150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11233794,
  'exchange_token': 43882,
  'tradingsymbol': 'NIFTY2250518200CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11234562,
  'exchange_token': 43885,
  'tradingsymbol': 'NIFTY2250518200PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18200,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11235842,
  'exchange_token': 43890,
  'tradingsymbol': 'NIFTY2250518250CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11237122,
  'exchange_token': 43895,
  'tradingsymbol': 'NIFTY2250518250PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18250,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11237890,
  'exchange_token': 43898,
  'tradingsymbol': 'NIFTY2250518300CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11238402,
  'exchange_token': 43900,
  'tradingsymbol': 'NIFTY2250518300PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18300,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11239170,
  'exchange_token': 43903,
  'tradingsymbol': 'NIFTY2250518350CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11239426,
  'exchange_token': 43904,
  'tradingsymbol': 'NIFTY2250518350PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18350,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 11702274,
  'exchange_token': 45712,
  'tradingsymbol': 'NIFTY2250518400CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12051970,
  'exchange_token': 47078,
  'tradingsymbol': 'NIFTY2250518400PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18400,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12052738,
  'exchange_token': 47081,
  'tradingsymbol': 'NIFTY2250518450CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12052994,
  'exchange_token': 47082,
  'tradingsymbol': 'NIFTY2250518450PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18450,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12053250,
  'exchange_token': 47083,
  'tradingsymbol': 'NIFTY2250518500CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 12053506,
  'exchange_token': 47084,
  'tradingsymbol': 'NIFTY2250518500PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18500,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13932802,
  'exchange_token': 54425,
  'tradingsymbol': 'NIFTY2250518550CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13933058,
  'exchange_token': 54426,
  'tradingsymbol': 'NIFTY2250518550PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18550,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13933314,
  'exchange_token': 54427,
  'tradingsymbol': 'NIFTY2250518600CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13933570,
  'exchange_token': 54428,
  'tradingsymbol': 'NIFTY2250518600PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18600,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13933826,
  'exchange_token': 54429,
  'tradingsymbol': 'NIFTY2250518650CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13934082,
  'exchange_token': 54430,
  'tradingsymbol': 'NIFTY2250518650PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18650,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13934338,
  'exchange_token': 54431,
  'tradingsymbol': 'NIFTY2250518700CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13934594,
  'exchange_token': 54432,
  'tradingsymbol': 'NIFTY2250518700PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18700,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13934850,
  'exchange_token': 54433,
  'tradingsymbol': 'NIFTY2250518750CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13935106,
  'exchange_token': 54434,
  'tradingsymbol': 'NIFTY2250518750PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18750,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13935362,
  'exchange_token': 54435,
  'tradingsymbol': 'NIFTY2250518800CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 13935618,
  'exchange_token': 54436,
  'tradingsymbol': 'NIFTY2250518800PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18800,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 14411010,
  'exchange_token': 56293,
  'tradingsymbol': 'NIFTY2250518850CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 14411266,
  'exchange_token': 56294,
  'tradingsymbol': 'NIFTY2250518850PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18850,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10286338,
  'exchange_token': 40181,
  'tradingsymbol': 'NIFTY2250518900CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10286850,
  'exchange_token': 40183,
  'tradingsymbol': 'NIFTY2250518900PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18900,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10287106,
  'exchange_token': 40184,
  'tradingsymbol': 'NIFTY2250518950CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10818818,
  'exchange_token': 42261,
  'tradingsymbol': 'NIFTY2250518950PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 18950,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10819074,
  'exchange_token': 42262,
  'tradingsymbol': 'NIFTY2250519000CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 10851330,
  'exchange_token': 42388,
  'tradingsymbol': 'NIFTY2250519000PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19000,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9126914,
  'exchange_token': 35652,
  'tradingsymbol': 'NIFTY2250519050CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9127170,
  'exchange_token': 35653,
  'tradingsymbol': 'NIFTY2250519050PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19050,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9129730,
  'exchange_token': 35663,
  'tradingsymbol': 'NIFTY2250519100CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9131010,
  'exchange_token': 35668,
  'tradingsymbol': 'NIFTY2250519100PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19100,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9131266,
  'exchange_token': 35669,
  'tradingsymbol': 'NIFTY2250519150CE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'CE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 },
 {
  'instrument_token': 9133826,
  'exchange_token': 35679,
  'tradingsymbol': 'NIFTY2250519150PE',
  'name': 'NIFTY',
  'last_price': 0,
  'expiry': '05-05-2022',
  'strike': 19150,
  'tick_size': 0.05,
  'lot_size': 50,
  'instrument_type': 'PE',
  'segment': 'NFO-OPT',
  'exchange': 'NFO'
 }
];
