import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import AuthProvider from 'providers/AuthProvider';

import createStore from './redux/createStore';

const initialState = window.__INITIAL_STATE__ || {
};

const store = createStore(initialState);

function App() {
 return (
  <Provider key="provider" store={store}>
   <AuthProvider>
    <BrowserRouter>
     <Routes />
    </BrowserRouter>
   </AuthProvider>
  </Provider>
 );
}

export default App;
