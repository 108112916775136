import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import NewTrade from './TradeForm';
import StandardTable from 'components/StandardTable';
import { getOpenTrades, getTrades } from 'redux/modules/trading/selectors';

import { Divider } from 'antd';
import { deleteTrade } from 'redux/modules/trading/actions';
import { useDispatch } from 'react-redux';
import ExitForm from '../containers/ExitTradeForm';

const TradeLog = () => {
 const trades = useSelector(getOpenTrades);

 const dispatch = useDispatch();
 const [modalVisible, setModalVisible] = useState(false);
 const [currentTrade, setCurrentTrade] = useState({});

 const exit = (trade) => {
  //  console.log('trade----', trade)
  setCurrentTrade(trade);
  setModalVisible(true);
 };

 const handleModalCancel = () => {
  setModalVisible(false);
  setCurrentTrade({});
 };

 const handleSave = () => {
  setCurrentTrade({});
 };

 // console.log('trades ---- ',trades)

 const columns = [
  {
   'title': 'No',
   'width': '20px',
   'render': (text, row, index) => index + 1
  },

  {
   'title': 'Stock',
   'dataIndex': 'stock',
   'key': 'stock',
   // sortDirections: 'ascend',
   'sorter': (a, b) => a.stock.localeCompare(b.stock),
   'defaultSortOrder': 'ascend'
  },

  {
   'title': 'Action',
   'dataIndex': 'action',
   'key': 'action'
  },
  {
   'title': 'Quantity',
   'dataIndex': 'quantity',
   'key': 'quantity'
  },
  {
   'title': 'Entry',
   'dataIndex': 'price',
   'key': 'price'
  },

  {
   'title': 'Stop Loss',
   'dataIndex': 'stopLoss',
   'key': 'stopLoss'
  },
  {
   'title': 'Target',
   'dataIndex': 'target',
   'key': 'target'
  },
  {
   'title': 'Breakeven',
   'dataIndex': 'breakeven',
   'key': 'breakeven',
   'render': (breakeven) => <span style={{ 'fontWeight': '500' }}>{breakeven}</span>
  },
  {
   'title': 'Risk',
   'dataIndex': 'risk',
   'key': 'risk',
   'render': (risk) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{risk}</span>),
   'align': 'center'
  },
  {
   'title': '% Risk',
   'dataIndex': 'riskPercentage',
   'key': 'riskPercentage',
   'render': (riskPercentage) => (<span style={{ 'fontWeight': '500',
    'color': 'red' }}>{riskPercentage}%</span>),
   'align': 'center'
  },
  {
   'title': 'Planned R:R',
   'dataIndex': 'riskReward',
   'key': 'riskReward',
   'align': 'center',
   'render': (riskReward) => (<span style={{ 'fontWeight': '500',
    'color': 'green' }}>1 : {riskReward}</span>)
  },
  {
   'title': 'Strategy',
   'dataIndex': 'strategy',
   'key': 'strategy',
   'render': (strategy) => (strategy.length > 1 ? <pre style={{ 'margin': '0px' }}>{strategy.join('\n')}</pre> : strategy)
  },
  {
   'render': (trade)=> (<>
    <a title="Edit" href="javascript:void(0)" onClick={() => setCurrentTrade(trade)}>
            Edit
    </a>
    <Divider type="vertical" />
    <a title="Exit" href="javascript:void(0)" onClick={() => exit(trade)}>
            Exit
    </a>
    <Divider type="vertical" />
    <Popconfirm
     title="Are you sure delete this trade"
     onConfirm={() => dispatch(deleteTrade(trade.id))}>
     <a><DeleteOutlined /></a>
    </Popconfirm>

   </>)
  }
 ];

 return (
  <>
   <NewTrade trade={currentTrade} key={currentTrade.id} handleSave={handleSave}/>
   <StandardTable columns={columns} dataSource={trades} />
   {
    modalVisible ? <ExitForm modalVisible={modalVisible} trade={currentTrade} handleCancel={handleModalCancel}/> : null
   }
  </>
 );
};

export default TradeLog;
