import { combineReducers } from 'redux';
import trading from './modules/trading/reducer';
import banking from './modules/banking/reducer';
import user from './modules/user/reducer';
import settings from './modules/settings/reducer';
import admin from './modules/admin/reducer';

const rootReducer = combineReducers({
 trading,
 banking,
 user,
 settings,
 admin
});

export default rootReducer;
