import React from 'react';
import TransactionForm from './TransactionForm';
import TransactionList from './TransactionList';

const Banking = () => (
  <>
    <TransactionForm />
    <TransactionList />
  </>
);

export default Banking;
