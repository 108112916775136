import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClosedPosition } from 'utils/brokerage';
import { getAccountBalance } from 'redux/modules/banking/selectors';
import { getNetProfit } from 'redux/modules/trading/selectors';

import moment from 'moment';

import {
 Input,
 Modal,
 Form, Row, Col,
 Select,
 Button,
 DatePicker,
 TimePicker
} from 'antd';
import { updateTrade } from 'redux/modules/trading/actions';
import { setAccountBalance } from 'redux/modules/banking/actions';
import './styles.less';

// console.log('styles ---- ', styles)

const basicRules = [{ 'required': true,
 'message': 'Please input a value' }];
const exitReasons = [
 'Hit Trailing Stop Loss',
 'Hit Stop Loss',
 'Hit Target',
 'Market Exit'
];
const emotions = [
 'Impatience',
 'Fear',
 'Greed',
 'Hope',
 'Confidence',
 'Uncertainty',
 'Bored'
];
const management = [
 'According To Plan',
 'Broke Rules',
 'Too Early',
 'Too Late',
 'Revenge Trade',
 'Chasing Setups',
 'Missing Setups'
];
const phychology = [
 'Feeling Great',
 'Feeling Down',
 'Feeling Indifferent',
 'Feeling Motivated',
 'Feeling Unmotivated'
];

const { TextArea } = Input;
const { Option } = Select;

const ExitForm = ({ trade, modalVisible, handleCancel }) => {
 const [form] = Form.useForm();
 const dispatch = useDispatch();

 const accountBalance = useSelector(getAccountBalance);
 // const netProfit1 =  useSelector(getNetProfit)
 const [date, setDate] = useState(trade.date ? trade.date : moment().format('YYYYMMDD'));
 const [entryTime, setEntryTime] = useState(trade.entryTime ? trade.entryTime : moment().format('h:mm a'));
 const [exitTime, setExitTime] = useState(trade.exitTime ? trade.exitTime : moment().format('h:mm a'));

 const haandleSubmit = values => {
  const { id, price, quantity, action } = trade;

  // if (netProfit) {
  //   accountBalance = accountBalance - parseFloat(netProfit)
  // }

  let buyPrice = price;

  let sellPrice = values.exitPrice;

  if (action === 'SHORT') {
   buyPrice = values.exitPrice;
   sellPrice = price;
  }

  const closedPosition = getClosedPosition(buyPrice, sellPrice, quantity);

  const accountChange = ((closedPosition.netProfit / accountBalance) * 100).toFixed(2);

  console.log('\n\n before closedPosition', closedPosition, accountBalance, closedPosition.netProfit);

  // accountBalance = accountBalance + closedPosition.netProfit
  // accountBalance = accountBalance + netProfit1

  // dispatch(setAccountBalance(accountBalance.toFixed(2)));

  console.log('closedPosition', closedPosition, accountChange);

  const tradeUpdate = {
   ...values,
   id,
   ...closedPosition,
   // accountBalance,
   accountChange,
   'breakeven': trade.breakeven,
   date,
   entryTime,
   exitTime
  };

  dispatch(updateTrade({
   ...tradeUpdate
  }));
  handleCancel();
 };

 const handleDateChange = (date, dateString) => {
  setDate(dateString.replace('-'));
 };

 return (
  <Modal
   destroyOnClose
   maskClosable={false}
   title={`${trade.stock}`}
   visible={modalVisible}
   onCancel={handleCancel}
   width={900}
   footer={null}>

   <Form

    form={form}
    layout="vertical"
    name="advanced_search"
    onFinish={haandleSubmit}
    hideRequiredMark
    initialValues={{
     'discipline': 7,
     ...trade
    }}
    style={{
     'padding': '24px',
     // background: '#fff',
     'border': '1px solid #d9d9d9',
     'marginBottom': '20px'
     // width: '90%'
    }}>
    <Row justify="space-between">
     <Col span={5} >
      <Form.Item
       rules={basicRules}
       label="Date">
       <DatePicker defaultValue={trade.date ? moment(trade.date, 'YYYYMMDD') : moment()} onChange={handleDateChange} />
      </Form.Item>
     </Col>
     <Col span={4} >
      <Form.Item
       rules={basicRules}
       label="Entry Time">
       <TimePicker minuteStep={5} defaultValue={trade.entryTime ? moment(trade.entryTime, 'h:mm a') : moment()} use12Hours format="h:mm a" onChange={(_, time)=>setEntryTime(time)} />
      </Form.Item>
     </Col>
     <Col span={4} >
      <Form.Item
       rules={basicRules}
       label="Exit Time">
       <TimePicker defaultValue={trade.exitTime ? moment(trade.exitTime, 'h:mm a') : moment()} use12Hours format="h:mm a" minuteStep={5} onChange={(_, time)=>setExitTime(time)} />
      </Form.Item>
     </Col>
     <Col span={4} >
      <Form.Item
       rules={basicRules}
       name="exitPrice"
       label="Actual Exit Price">
       <Input autoComplete="off" />
      </Form.Item>

     </Col>
     <Col span={4} >
      <Form.Item
       rules={basicRules}
       name="paperProfitPrice"
       label="Paper Profit Price">
       <Input autoComplete="off" />
      </Form.Item>
     </Col>
     <Col span={4}>
      <Form.Item
       rules={basicRules}
       name="discipline"
       label="Discipline">
       <Select>
        <Option value="1">1</Option>
        <Option value="2">2</Option>
        <Option value="3">3</Option>
        <Option value="4">4</Option>
        <Option value="5">5</Option>
        <Option value="6">6</Option>
        <Option value="7">7</Option>
        <Option value="8">8</Option>
        <Option value="9">9</Option>
        <Option value="10">10</Option>

       </Select>
      </Form.Item>
     </Col>
     <Col span={6} >
      <Form.Item
       rules={basicRules}
       name="exitReason"
       label="Reason for Exit">
       <Select mode="multiple">
        {exitReasons.map(item => (
         <Option key={item}>{item}</Option>
        ))}
       </Select>
      </Form.Item>
     </Col>
     <Col span={6} >
      <Form.Item
       rules={basicRules}
       name="management"
       label="Management">
       <Select mode="multiple">
        {management.map(item => (
         <Option key={item}>{item}</Option>
        ))}
       </Select>
      </Form.Item>
     </Col>
     <Col span={6} >
      <Form.Item
       rules={basicRules}
       name="psychology"
       label="Psychology">
       <Select mode="multiple">
        {phychology.map(item => (
         <Option key={item}>{item}</Option>
        ))}
       </Select>
      </Form.Item>
     </Col>
     <Col span={6} >
      <Form.Item
       rules={basicRules}
       name="emotion"
       label="Emotion">
       <Select mode="multiple">
        {emotions.map(item => (
         <Option key={item}>{item}</Option>
        ))}
       </Select>
      </Form.Item>
     </Col>

     <Col span={17} >
      <Form.Item
       rules={basicRules}
       name="notes"
       label="Notes">
       <TextArea rows={4} />
      </Form.Item>

     </Col>
    </Row>
    <Row justify="end">
     <Col>

      <Button type="primary" htmlType="submit">
            Save
      </Button>
     </Col>
    </Row>
   </Form>
  </Modal>
 );
};

export default ExitForm;
