import React, { useState } from "react";
import StandardTable from "components/StandardTable";
import { Button, Image } from "antd";
import { database } from "service/firebase";
import useOrders from "./hooks/useOrders";

const handleCancel = async (item) => {
  try {
    const newReference = database.ref("actions").push();

    await newReference.set({
      type: "CANCEL_ORDER",
      userId: item.userId,
      orderId: item.orderId,
      instrumentToken: item.instrumentToken,
    });
  } catch (error) {
    console.log("Cancel Order error", error);
  }
};

const OrderHistory = () => {
  const zones = useOrders();

  const columns = [
    {
      title: "No",
      width: "20px",
      render: (text, row, index) => index + 1,
    },
    // {
    //  'title': 'Strike',
    //  'key': 'strike',
    //  //  'dataIndex': 'strike',
    //  'render': (zone) => (<span style={{ 'fontWeight': '500',
    //   'color': 'yellow' }}>
    //   <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
    //    {zone.strike}
    //   </a>

    //  </span>)
    // },

    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      // sortDirections: 'ascend',
      // sorter: (a, b) => a && a.stock && a.stock.localeCompare(b.stock),
      defaultSortOrder: "ascend",
    },
    // {
    //  'title': 'Date',
    //  'key': 'createdDate',
    //  'dataIndex': 'createdDate'
    // },
    // {
    //  'title': 'Trend',
    //  'dataIndex': 'trend',
    //  'key': 'trend'
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: (a, b) => a.action.localeCompare(b.action),
    },
    // {
    //  'title': 'Quantity',
    //  'dataIndex': 'quantity',
    //  'key': 'quantity'
    // },
    {
      title: "Entry",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => parseInt(a.price) - parseInt(b.price),
      render: (strike) => (
        <span
          style={{
            fontWeight: "500",
            // 'color': 'yellow'
          }}
        >
          {strike}
        </span>
      ),
    },

    {
      title: "Stop Loss",
      dataIndex: "stopLoss",
      key: "stopLoss",
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
    },
    {
      title: "Planned R:R",
      key: "riskReward",
      align: "center",
      render: (trade) => (
        <span style={{ fontWeight: "500" }}>
          1 :{" "}
          {isNaN((trade.reward / trade.risk).toFixed(2))
            ? ""
            : (trade.reward / trade.risk).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Target Points",
      key: "target",
      align: "center",
      render: (trade) => (
        <span style={{ fontWeight: "500", color: "red" }}>
          {Math.abs(trade.target - trade.price)}
        </span>
      ),
    },
    {
      title: "Status",
      key: "target",
      align: "center",
      render: (trade) => (
        <span style={{ fontWeight: "500", color: "red" }}>
          {trade.status}
        </span>
      ),
    },
    // {
    //  'align': 'center',
    //  'render': (trade)=>
    //   (<>
    //    <Image
    //     width={40}
    //     height={40}
    //     src="/tv.png"
    //     preview={{
    //      'src': trade.chartImage
    //     }}/>
    //   </>)
    // },
    {
      align: "center",
      render: (trade) =>
        trade.status === "OPEN" && (
          <>
            <Button
              style={{ fontWeight: "500" }}
              onClick={() => handleCancel(trade)}
              type="primary"
            >
              Cancel
            </Button>
          </>
        ),
    },
  ];

  return (
    <StandardTable
      columns={columns}
      dataSource={zones}
      style={{ marginBottom: "20px" }}
    />
  );
};

export default OrderHistory;
