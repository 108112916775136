const userKeys = 'displayName,email,emailVerified,isAnonymous,phoneNumber,photoURL,uid'.split(
 ','
);

const createUser = (_user) => {
 const user = userKeys.reduce(
  (prev, key) => ({
   ...prev,
   [key]: _user[key] === null ? undefined : _user[key]
  }),
  {}
 );

 return user;
};

export default createUser;
