import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StandardTable from 'components/StandardTable';
import { getStocks as getStocksAction, deleteStock, editStock } from 'redux/modules/settings/actions';
import { getStocks } from 'redux/modules/settings/selectors';

import { DeleteTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';

const StrategyList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStocksAction());
  }, [dispatch]);

  const stocks = useSelector(getStocks);

  const columns = [
    {
      'title': 'No',
      'dataIndex': 'index',
      'key': 'index',
      'width': '20px'

    },
    {
      'title': 'Name',
      // 'dataIndex': 'name',
      'key': 'name',
      'render': (stock)=> (
        <a title="Edit" href="javascript:void(0)" onClick={() => dispatch(editStock(stock))}>
          {stock.name}
        </a>
      )
    },
    {
      'title': 'Quantity',
      'dataIndex': 'quantity',
      'key': 'quantity'
    },
    {
      'title': 'Trailing Stop Loss',
      'dataIndex': 'trailingStopLoss',
      'key': 'trailingStopLoss'
    },
    {
      'title': 'Target Break',
      'dataIndex': 'targetBreak',
      'key': 'targetBreak'
    },
    {
      'render': (item)=> (<>
        <Popconfirm
          title="Are you sure delete this stock"
          onConfirm={() => dispatch(deleteStock(item.id))}>
          <a><DeleteTwoTone /></a>
        </Popconfirm>
      </>)
    }

  ];

  return (
    <StandardTable columns={columns} dataSource={stocks} />
  );
};

export default StrategyList;
