import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StandardTable from 'components/StandardTable';
import { getStrategies as getStrategiesAction, deleteStrategy } from 'redux/modules/trading/actions';
import { getStrategies } from 'redux/modules/trading/selectors';
import { DeleteTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';

const StrategyList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStrategiesAction());
  }, [dispatch]);

  const strategies = useSelector(getStrategies);

  const columns = [
    {
      'title': 'No',
      'dataIndex': 'index',
      'key': 'index',
      'width': '20px'
    },
    {
      'title': 'Name',
      'dataIndex': 'name',
      'key': 'name'
    },
    {
      'title': 'Check List',
      'dataIndex': 'checkList',
      'key': 'checkList'
    },
    {
      'title': 'Description',
      'dataIndex': 'description',
      'key': 'description'
    },
    {
      'render': (item)=> (<>
        <Popconfirm
          title="Are you sure delete this strategy？"
          onConfirm={() => dispatch(deleteStrategy(item.id))}>
          <a><DeleteTwoTone /></a>
        </Popconfirm>
      </>)
    }

  ];

  return (
    <StandardTable columns={columns} dataSource={strategies} />
  );
};

export default StrategyList;
