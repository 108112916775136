const stocks = [
  {
    instrumentToken: 256265,
    tradingsymbol: "NIFTY",
    name: "NIFTY",
    quantity: 50,

    exchange: "NFO",
  },
  {
    instrumentToken: 260105,
    tradingsymbol: "BANKNIFTY",
    name: "BANKNIFTY",
    quantity: 15,
    exchange: "NFO",
  },
  {
    instrumentToken: 9070082,
    tradingsymbol: "ACC",
    name: "ACC",
    quantity: 300,
    exchange: "NFO",
  },
  {
    instrumentToken: 60417,
    tradingsymbol: "ASIANPAINT",
    name: "ASIANPAINT",
    quantity: 200,

    exchange: "NFO",
  },
  {
    instrumentToken: 9156098,
    tradingsymbol: "AXISBANK",
    name: "AXISBANK",
    quantity: 625,
    exchange: "NFO",
  },
  {
    instrumentToken: 4268801,
    tradingsymbol: "BAJAJFINSV",
    name: "BAJAJFINSV",
    quantity: 500,

    exchange: "NFO",
  },
  {
    instrumentToken: 81153,
    tradingsymbol: "BAJFINANCE",
    name: "BAJFINANCE",
    quantity: 125,

    exchange: "NFO",
  },
  {
    instrumentToken: 579329,
    tradingsymbol: "BANDHANBNK",
    name: "BANDHANBNK",
    quantity: 2500,

    exchange: "NFO",
  },
  {
    instrumentToken: 1195009,
    tradingsymbol: "BANKBARODA",
    name: "BANKBARODA",
    quantity: 5850,

    exchange: "NFO",
  },
  {
    instrumentToken: 558337,
    tradingsymbol: "BOSCHLTD",
    name: "BOSCHLTD",
    quantity: 50,

    exchange: "NFO",
  },
  {
    instrumentToken: 177665,
    tradingsymbol: "CIPLA",
    name: "CIPLA",
    quantity: 650,

    exchange: "NFO",
  },
  {
    instrumentToken: 3771393,
    tradingsymbol: "DLF",
    name: "DLF",
    quantity: 1650,

    exchange: "NFO",
  },
  {
    instrumentToken: 1895937,
    tradingsymbol: "GLENMARK",
    name: "GLENMARK",
    quantity: 1450,

    exchange: "NFO",
  },
  {
    instrumentToken: 315393,
    tradingsymbol: "GRASIM",
    name: "GRASIM",
    quantity: 475,

    exchange: "NFO",
  },
  {
    instrumentToken: 340481,
    tradingsymbol: "HDFC",
    name: "HDFC",
    quantity: 300,

    exchange: "NFO",
  },
  {
    instrumentToken: 341249,
    tradingsymbol: "HDFCBANK",
    name: "HDFCBANK",
    quantity: 550,

    exchange: "NFO",
  },
  {
    instrumentToken: 1270529,
    tradingsymbol: "ICICIBANK",
    name: "ICICIBANK",
    quantity: 700,

    exchange: "NFO",
  },
  {
    instrumentToken: 10829570,
    tradingsymbol: "INFY",
    name: "INFY",
    quantity: 400,
    exchange: "NFO",
  },
  {
    instrumentToken: 492033,
    tradingsymbol: "KOTAKBANK",
    name: "KOTAKBANK",
    quantity: 400,
    exchange: "NFO",
  },
  {
    instrumentToken: 2939649,
    tradingsymbol: "LT",
    name: "LT",
    quantity: 300,
    exchange: "NFO",
  },
  {
    instrumentToken: 519937,
    tradingsymbol: "M&M",
    name: "M&M",
    quantity: 700,
    exchange: "NFO",
  },
  {
    instrumentToken: 4598529,
    tradingsymbol: "NESTLEIND",
    name: "NESTLEIND",
    quantity: 40,
    exchange: "NFO",
  },
  {
    instrumentToken: 738561,
    tradingsymbol: "RELIANCE",
    name: "RELIANCE",
    quantity: 250,
    exchange: "NFO",
  },
  {
    instrumentToken: 779521,
    tradingsymbol: "SBIN",
    name: "SBIN",
    quantity: 1500,

    exchange: "NFO",
  },
  {
    instrumentToken: 806401,
    tradingsymbol: "SIEMENS",
    name: "SIEMENS",
    quantity: 275,
    exchange: "NFO",
  },
  {
    instrumentToken: 837889,
    tradingsymbol: "SRF",
    name: "SRF",
    quantity: 375,
    exchange: "NFO",
  },
  {
    instrumentToken: 2953217,
    tradingsymbol: "TCS",
    name: "TCS",
    quantity: 175,
    exchange: "NFO",
  },
  {
    instrumentToken: 2952193,
    tradingsymbol: "ULTRACEMCO",
    name: "ULTRACEMCO",
    quantity: 100,
    exchange: "NFO",
  },
  {
    instrumentToken: 232961,
    tradingsymbol: "EICHERMOT",
    name: "EICHERMOT",
    quantity: 175,
    exchange: "NFO",
  },
  {
    instrumentToken: 348929,
    tradingsymbol: "HINDALCO",
    name: "HINDALCO",
    quantity: 1400,
    exchange: "NFO",
  },
];

const date = new Date();

// Check if the current day is greater than 20
if (date.getDate() > 20) {
  // Set the month to the next month
  date.setMonth(date.getMonth() + 1);
}

const year = date.getFullYear().toString().slice(2);
const month = date.toLocaleString("default", { month: "short" }).toUpperCase();

const stocksMap = stocks.reduce((acc, obj) => {
  delete obj.exchange_token;
  delete obj.name;

  console.log(obj.tradingsymbol);

  acc[obj.tradingsymbol] = {
    ...obj,
    tradingsymbol: `${obj.tradingsymbol}${year}${month}FUT`,
  };

  return acc;
}, {});

console.log('stocksMap :>> ', stocksMap);

export default stocksMap;
