import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAccountBalance } from "redux/modules/banking/selectors";
import { updateOrder } from "redux/modules/trading/actions";

import { useDispatch } from "react-redux";

import { Form, Row, Col, Input, Button } from "antd";
import { getClosedPosition } from "utils/brokerage";

const basicRules = [{ required: true, message: "Please input a value" }];

const OrderForm = ({ order = {}, handleSave }) => {
  const [form] = Form.useForm();
  const [riskPercentage, setRiskPercentage] = useState(
    order.riskPercentage || 0
  );
  const [risk, setRisk] = useState(order.risk || 0);
  const [breakevenTrigger, setBreakevenTrigger] = useState(
    order.breakevenTrigger || 0
  );

  const [riskReward, setRiskReward] = useState(order.riskReward || 0);
  const accountBalance = useSelector(getAccountBalance);

  const dispatch = useDispatch();

  console.log("\n\n -- Update order ", order);

  const getBreakeven = () => {
    const price = parseFloat(form.getFieldValue("price") || order.price);
    const stopLoss = parseFloat(
      form.getFieldValue("stopLoss") || order.stopLoss
    );
    const { quantity } = order;
    const action = order.transactionType;

    let { breakeven } = getClosedPosition(price, stopLoss, quantity);

    if (action === "SELL") {
      breakeven = price - breakeven;
    } else {
      breakeven = price + breakeven;
    }

    breakeven = Math.ceil(breakeven / 0.05) * 0.05;

    return breakeven.toFixed(2);
  };

  const handleSubmit = (values) => {
    let trailingStopLoss =
      Math.abs(
        parseFloat(form.getFieldValue("target")) - parseFloat(breakevenTrigger)
      ) / 20;

    trailingStopLoss = (Math.ceil(trailingStopLoss / 0.05) * 0.05).toFixed(2);

    dispatch(
      updateOrder({
        // ...order,
        ...values,
        risk,
        riskPercentage,
        riskReward,
        breakeven: getBreakeven(),
        trailingStopLoss,
        // breakevenTrigger,
        orderId: order.orderId,
        status: order.status,
      })
    );

    form.resetFields();
    handleSave();
  };

  const handleRisk = () => {
    const action = order.transactionType;
    const price = parseFloat(form.getFieldValue("price") || 0);
    const stopLoss = form.getFieldValue("stopLoss") || 0;
    const { quantity } = order;
    const target = parseFloat(form.getFieldValue("target") || 0);
    const { breakeven } = getClosedPosition(price, stopLoss, quantity);

    let breakevenTick = Math.abs((target - price) * 0.5);

    breakevenTick = Math.ceil(breakevenTick / 0.05) * 0.05;

    if (action === "BUY") {
      setBreakevenTrigger((price + breakevenTick).toFixed(2));
    } else {
      setBreakevenTrigger((price - breakevenTick).toFixed(2));
    }

    const risk = Math.abs(price - stopLoss) * quantity + breakeven;
    const riskPercentage = (risk / accountBalance) * 100;
    const reward =
      ((Math.abs(target - price) * quantity) / accountBalance) * 100;

    setRisk(risk.toFixed(2));
    setRiskReward((reward / riskPercentage).toFixed(2));
    setRiskPercentage(riskPercentage.toFixed(2));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="order_update"
      onFinish={handleSubmit}
      hideRequiredMark
      // className={styles.tradeForm}
      initialValues={{
        ...order,
      }}
      style={{
        padding: "24px",
        // background: '#fff',
        border: "1px solid #d9d9d9",
        marginBottom: "20px",
        // width: '90%'
      }}
    >
      <Row gutter={24}>
        {order.status === "OPEN" && (
          <>
            <Col>
              <Form.Item rules={basicRules} name="price" label="Entry Price">
                <Input autoComplete="off" onChange={handleRisk} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item rules={basicRules} name="stopLoss" label="Stop Loss">
                <Input autoComplete="off" onChange={handleRisk} />
              </Form.Item>
            </Col>
          </>
        )}
        <Col>
          <Form.Item rules={basicRules} name="target" label="Target">
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label="Breakeven">
            <span style={{ fontWeight: "500" }}>
              {getBreakeven() || order.breakeven}
            </span>
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label="Breakeven Trigger">
            <span style={{ fontWeight: "500" }}>{breakevenTrigger}</span>
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label="Risk">
            <span style={{ fontWeight: "500", color: "red" }}>{risk}</span>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="% Risk Long">
            <span style={{ fontWeight: "500", color: "red" }}>
              {riskPercentage}%
            </span>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item style={{ alignItems: "centre" }} label="Planned R:R">
            <span
              style={{ fontWeight: "bold", marginLeft: "20px" }}
            >{`1: ${riskReward}`}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderForm;
