import React from 'react';
import useAuth from 'hooks/useAuth';
import LandingPage from 'pages/Landing';

const SignIn = () => {
 const { signIn } = useAuth();

 return <LandingPage signIn={signIn} />;

 // return (<div
 //   style={{
 //     display: 'flex',
 //     justifyContent: 'center',
 //     alignItems: 'center',
 //   }}>

 // <Button type="primary" icon={<GoogleOutlined />}  onClick={signIn}>Sign In</Button>
 // </div>)
};

export default SignIn;
