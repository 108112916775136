import React from 'react';
import { Card, Tabs, Checkbox, Row, Col, Button } from 'antd';
import {
 SaveOutlined
} from '@ant-design/icons';
import styles from './styles.less';

const { TabPane } = Tabs;

const processList = [
 'Reviewed Yesterday\'s Trades',
 'Noted Today\'s News Items',
 'Went Through Watchlist',
 'Light Workout Completed',
 'Had A Healthy Meal',
 'Meditated',
 'Failed To Prepare'
];

const psychology = [
 'Feeling Great',
 'Feeling Down',
 'Feeling Indifferent',
 'Feeling Motivated',
 'Feeling Unmotivated'
];

const emotion = [
 'Impatience',
 'Fear',
 'Greed',
 'Hope',
 'Confidence',
 'Uncertainty'
];

function callback(key) {
 console.log(key);
}

const TradingProcess = () => (
 <Tabs defaultActiveKey="1">
  <TabPane tab="Entry" key="1">
   <Row gutter={15} className={styles.process}>

    <Col span={5}>
     <Card
      title="Preparation" bordered={false} actions={[<Button
       key="card_button"
       icon={<SaveOutlined />}
       type="primary"> Save</Button>]}>
      <Checkbox.Group options={processList} />
     </Card>
    </Col>

   </Row>
  </TabPane>
  <TabPane tab="Exit" key="2" >

   <Row gutter={10} className={styles.process} >

    <Col span={5}>
     <Card title="Psychology" bordered={false} >
      <Checkbox.Group options={psychology} />
     </Card>
    </Col>
    <Col span={5}>
     <Card title="Emotion" bordered={false} >
      <Checkbox.Group options={emotion} />
     </Card>
    </Col>

   </Row>
   <Row style={{ 'marginTop': '20px' }}>
    <Col span={10} style={{ 'textAlign': 'right' }}>
     <Button
      icon={<SaveOutlined />}
      type="primary"> Save</Button>
    </Col>
   </Row>
  </TabPane>

 </Tabs>

);

export default TradingProcess;
