import React, { useState } from "react";
import moment from "moment";
import TradeForm from "./TradeForm";
import StandardTable from "components/StandardTable";
import { FileAddOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Image, Tag } from "antd";
import { getOrder, buttonType } from "./utils";
import useTrades from "./hooks/useTrades";
import { database } from "service/firebase";
import orderVariety from "constants/orderVariety";
import FutureForm from "./FutureForm";
import { getUserId } from "../../service/firebase";

const Trades = () => {
  const [currentTrade, setCurrentTrade] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const zones = useTrades("zones");

  const placeOrder = async (zone) => {
    try {
      const sandheep = database.ref("actions").push();

      const order = getOrder(zone);
      const userId = getUserId();

      sandheep.set({
        type: "PLACE_FUTURE_ORDER",
        //  'type': 'PLACE_ORDER',
        userId: "qyxPMfWY3gVDLjzVqLtyp3CxEo12",
        order: {
          ...order,
          tradingsymbol:
            userId !== "EnCww0QlDEVm4zicLtEAYq7nZbe2"
              ? order.tradingsymbol
              : `${order.tradingsymbol}-FUT-TEST`

          // 'quantity': 100
        },
        variety: orderVariety.REGULAR,
      });

      //  const renjith = database
      //   .ref('actions')
      //   .push();

      //  renjith
      //   .set({
      //    'type': 'PLACE_FUTURE_ORDER',
      //    //  'type': 'PLACE_ORDER',
      //    'userId': 'iOt7QxsZDQbU2cuDWtXc5U4md6x2',
      //    'order': getOrder(zone),
      //    'variety': orderVariety.REGULAR
      //   });

      database.ref(`/zones/${zone.id}`).update({
        ...zone,
        date: moment().format("YYYY-MM-DD hh:mm a"),
        status: "OPEN",
      });
    } catch (error) {
      console.log("tradingZone error", error);
    }
  };

  const handleSave = (order) => {
    console.log("order", order);
    try {
      if (order.id) {
        database
          .ref(`/zones/${order.id}`)
          .update({
            ...order,
            date: moment().format("YYYY-MM-DD hh:mm a"),
          })
          .then(() => console.log("Data updated."));
      } else {
        const newReference = database.ref("/zones").push();

        newReference
          .set({
            id: newReference.key,
            ...order,
            date: moment().format("YYYY-MM-DD hh:mm a"),
          })
          .then(() => console.log("Data updated."));
      }
    } catch (error) {
      console.log("tradingZone error", error);
    }

    setCurrentTrade({});
    setModalVisible(false);
  };

  const editZone = (zone) => {
    setCurrentTrade(zone);
    setModalVisible(true);
  };

  const handleDelete = (orderId) => {
    database.ref(`/zones/${orderId}`).remove();
  };

  const columns = [
    {
      title: "No",
      width: "20px",
      render: (text, row, index) => index + 1,
    },
    // {
    //  'title': 'Strike',
    //  'key': 'strike',
    //  //  'dataIndex': 'strike',
    //  'render': (zone) => (<span style={{ 'fontWeight': '500',
    //   'color': 'yellow' }}>
    //   <a title="Edit" href="javascript:void(0)" onClick={() => editZone(zone)}>
    //    {zone.strike}
    //   </a>

    //  </span>)
    // },

    {
      title: "Stock",
      // dataIndex: 'stock',
      key: "stock",
      // sortDirections: 'ascend',
      // sorter: (a, b) => a && a.stock && a.stock.localeCompare(b.stock),
      defaultSortOrder: "ascend",
      render: (zone) => (
        <a
          title="Edit"
          href="javascript:void(0)"
          onClick={() => editZone(zone)}
        >
          {zone.stock}
        </a>
      ),
    },
    // {
    //  'title': 'Symbol',
    //  'key': 'optionSymbol',
    //  'dataIndex': 'optionSymbol'
    // },
    // {
    //  'title': 'Date',
    //  'key': 'date',
    //  'dataIndex': 'date'
    // },
    // {
    //  'title': 'Trend',
    //  'dataIndex': 'trend',
    //  'key': 'trend'
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: (a, b) => a.action.localeCompare(b.action),
    },
    // {
    //  'title': 'Quantity',
    //  'dataIndex': 'quantity',
    //  'key': 'quantity'
    // },
    {
      title: "Entry",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => parseInt(a.price) - parseInt(b.price),
      render: (strike) => (
        <span
          style={{
            fontWeight: "500",
            // 'color': 'yellow'
          }}
        >
          {strike}
        </span>
      ),
    },

    {
      title: "Stop Loss",
      dataIndex: "stopLoss",
      key: "stopLoss",
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
    },
    {
      title: "Planned R:R",
      key: "riskReward",
      align: "center",
      render: (trade) => (
        <span style={{ fontWeight: "500" }}>
          1 :{" "}
          {isNaN((trade.reward / trade.risk).toFixed(2))
            ? ""
            : (trade.reward / trade.risk).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Target Points",
      key: "target",
      align: "center",
      render: (trade) => (
        <span style={{ fontWeight: "500", color: "red" }}>
          {Math.abs(trade.target - trade.price)}
        </span>
      ),
    },
    {
      title: "Risk",
      key: "risk",
      align: "center",
      render: (trade) => (
        <span style={{ fontWeight: "bold", color: "red" }}>{trade.risk}</span>
      ),
    },
    // {
    //  'title': 'Profit',
    //  'key': 'reward',
    //  'align': 'center',
    //  'render': (trade) => (<span
    //   style={{ 'fontWeight': 'bold',
    //    'color': 'green'
    //   }}>{trade.reward}</span>)
    // },
    // {
    //  'align': 'center',
    //  'render': (trade)=>
    //   (<>
    //    <Image
    //     width={40}
    //     height={40}
    //     src="/tv.png"
    //     preview={{
    //      'src': trade.chartImage
    //     }}/>
    //   </>)
    // },
    {
      align: "center",
      render: (trade) =>
        trade.status === "PENDING" && (
          // trade.status === 'OPEN' &&
          <>
            <Button
              style={{ fontWeight: "500" }}
              onClick={() => placeOrder(trade)}
              type={buttonType[trade.action]}
            >
              {trade.action}
            </Button>
          </>
        ),
    },

    {
      render: (trade) => (
        <Popconfirm
          title="Are you sure delete this order"
          onConfirm={() => handleDelete(trade.id)}
        >
          <a>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            setModalVisible(true);
          }}
          icon={<FileAddOutlined />}
        >
          New Trade
        </Button>
      </div>
      <StandardTable
        columns={columns}
        dataSource={zones}
        style={{ marginBottom: "20px" }}
      />

      <Modal
        destroyOnClose
        maskClosable={false}
        width={760}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
          setCurrentTrade({});
        }}
        title="Trade Form"
        visible={isModalVisible}
      >
        <FutureForm
          data={currentTrade}
          key={currentTrade.id}
          handleSave={handleSave}
        />
      </Modal>
    </>
  );
};

export default Trades;
