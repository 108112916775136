import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Row, Col, Input, Button, Switch, DatePicker } from 'antd';
import { addTransaction } from 'redux/modules/banking/actions';

const basicRules = [{ 'required': true,
 'message': 'Please input a value' }];

const TransactionForm = () => {
 const [form] = Form.useForm();
 const dispatch = useDispatch();
 const [action, setAction] = useState('DEPOSIT');

 const handleSave = values => {
  dispatch(addTransaction({
   ...values,
   action
  }));
  form.resetFields();
 };

 const handleAction = (isChecked) => {
  if (isChecked) {
   setAction('DEBIT');
  } else {
   setAction('DEPOSIT');
  }
 };

 return (
  <Form
   form={form}
   layout="vertical"
   name="strategy"
   hideRequiredMark
   onFinish={handleSave}
   style={{
    'padding': '24px',
    // background: '#fff',
    'border': '1px solid #d9d9d9',
    'marginBottom': '20px'
   }}>
   <Row gutter={24}>
    <Col span={8} >
     <Form.Item
      name="date"
      rules={basicRules}
      label="Date">
      <DatePicker />

     </Form.Item>
    </Col>
    <Col span={8} >
     <Form.Item
      name="action"
      rules={basicRules}
      label="Action">
      <Switch onClick={handleAction} checkedChildren="DEBIT" unCheckedChildren="DEPOSIT" />

     </Form.Item>
    </Col> <Col span={8} >
     <Form.Item
      name="amount"
      rules={basicRules}
      label="Amount">
      <Input />
     </Form.Item>
    </Col>
   </Row>
   <Row>
    <Col span={4} offset={20} style={{ 'textAlign': 'right' }}>
     <Button type="primary" htmlType="submit">
            Save
     </Button>
    </Col>
   </Row>
  </Form>
 );
};

export default TransactionForm;
