import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccountBalance } from "redux/modules/banking/selectors";
import {
  addTradingZone,
  getStrategies as getStrategiesAction,
  updateTradingZone
} from "redux/modules/trading/actions";

import { Form, Row, Col, Input, Button, Select, Tag, Checkbox } from "antd";
import { getClosedPosition } from "utils/brokerage";
import { getStocks } from "redux/modules/settings/selectors";

import styles from "./styles.less";
import options from "constants/options";

const { Option } = Select;

const basicRules = [{ required: true,
  message: "Please input a value" }];

const OptionsZoneForm = ({ trade = {}, handleSave }) => {
  const [optionType, setOptiontype] = useState("");
  const [form] = Form.useForm();
  const [riskPercentage, setRiskPercentage] = useState(
    trade.riskPercentage || 0
  );
  const [risk, setRisk] = useState(trade.risk || 0);

  const [reward, setReward] = useState(trade.reward || 0);
  const accountBalance = useSelector(getAccountBalance);
  const stocks = options.filter(
    (option) => option.instrument_type === form.getFieldValue("optionType")
  );
  const action = "BUY";
  const dispatch = useDispatch();

  //  useEffect(() => {
  //   dispatch(getStrategiesAction());
  //   dispatch(getStocksAction());
  //  }, [dispatch]);

  const getBreakeven = () => {
    const price = parseFloat(form.getFieldValue("price"));
    const stopLoss = parseFloat(form.getFieldValue("stopLoss"));
    const quantity = parseFloat(form.getFieldValue("quantity"));

    // const action = 'BUY'; // form.getFieldValue('action');

    let { breakeven } = getClosedPosition(price, stopLoss, quantity);

    if (action === "SELL") {
      breakeven = price - breakeven;
    } else {
      breakeven = price + breakeven;
    }

    breakeven = Math.ceil(breakeven / 0.05) * 0.05;

    return breakeven.toFixed(2);
  };

  const handleStockChange = (name) => {
    const currentStock = stocks.find((obj) => obj.tradingsymbol === name) || {};

    form.setFieldsValue({ quantity: currentStock.quantity });
  };

  const onFinish = (values) => {
    const currentStock =
      stocks.find((obj) => obj.tradingsymbol === form.getFieldValue("stock")) ||
      {};

    const { trailingStopLoss, targetBreak, strike } = currentStock;

    console.log("values", values, targetBreak);
    console.log("stocks", stocks, currentStock);
    console.log("optionType", optionType);
    console.log("currentStock", currentStock);

    if (trade.id) {
      dispatch(
        updateTradingZone({
          ...trade,
          ...values,
          action,
          id: trade.id,
          risk,
          // riskPercentage,
          reward,
          breakeven: getBreakeven(),
          trailingStopLoss,
          targetBreak,
          orderStatus: "PENDING",
          timeFrame: 20,
          strike,
          lotSize: 25
        })
      );
    } else {
      dispatch(
        addTradingZone({
          ...values,
          action,
          timeFrame: 20,
          // date:  moment().format('YYYYMMDD'),
          risk,
          // riskPercentage,
          reward,
          breakeven: getBreakeven(),
          orderStatus: "PENDING",
          trailingStopLoss,
          targetBreak,
          strike,
          lotSize: 25
        })
      );
    }

    form.resetFields();
    handleSave();
  };

  const handleRisk = () => {
    // const action = form.getFieldValue('action');

    const price = parseFloat(form.getFieldValue("price") || 0);
    const stopLoss = form.getFieldValue("stopLoss") || 0;
    const quantity = form.getFieldValue("quantity") || 0;
    const target = parseFloat(form.getFieldValue("target") || 0);
    const { breakeven } = getClosedPosition(price, stopLoss, quantity);

    let breakevenTrigger = 0;

    let breakevenTick = Math.abs((target - price) * 0.5);

    breakevenTick = Math.ceil(breakevenTick / 0.05) * 0.05;

    if (action === "BUY") {
      breakevenTrigger = (price + breakevenTick).toFixed(2);
    } else {
      breakevenTrigger = (price - breakevenTick).toFixed(2);
    }

    const risk = Math.abs(price - stopLoss) * quantity + breakeven;
    // const riskPercentage = (risk / accountBalance) * 100;
    const reward = Math.abs(target - price) * quantity; // - breakeven;

    setRisk(risk.toFixed(2));
    setReward(reward);
    // setRiskReward((reward / riskPercentage).toFixed(2));
    // setRiskPercentage(riskPercentage.toFixed(2));

    form.setFieldsValue({ breakevenTrigger });
  };

  const handleOptionType = (value) => {
    setOptiontype(value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="advanced_search"
      onFinish={onFinish}
      hideRequiredMark
      className={styles.tradeForm}
      initialValues={{
        ...trade
      }}
      style={{
        padding: "24px",
        // background: '#fff',
        border: "1px solid #d9d9d9",
        marginBottom: "20px"
        // width: '90%'
      }}>
      <Row gutter={24}>
        <Col>
          <Form.Item rules={basicRules} name="optionType" label="Option Type">
            <Select
              defaultValue=""
              style={{ width: 80 }}
              allowClear
              onChange={handleOptionType}>
              <Option value="" />
              <Option value="CE">CE</Option>
              <Option value="PE">PE</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item rules={basicRules} name="stock" label="Stock">
            <Select
              defaultValue=""
              showSearch
              onChange={handleStockChange}
              style={{ width: "210px" }}>
              {stocks.map((item) => (
                <Option key={item.tradingsymbol} value={item.tradingsymbol}>
                  {item.strike}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col>
     <Form.Item
      rules={basicRules}
      name="timeFrame"
      label="Time Frame">
      <Select style={{ 'width': '150px' }} >
       <Option key="20">20</Option>
       <Option key="45">45</Option>
       <Option key="60">60</Option>
      </Select>
     </Form.Item>
    </Col> */}
        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "70px" }}
            name="quantity"
            label="Quantity">
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "70px" }}
            name="price"
            label="Entry Price">
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "80px" }}
            name="stopLoss"
            label="Stop Loss">
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            rules={basicRules}
            style={{ width: "70px" }}
            name="target"
            label="Target">
            <Input autoComplete="off" onChange={handleRisk} />
          </Form.Item>
        </Col>

        {/* <Col>
     <Form.Item
      label="Breakeven">
      <span style={{ 'fontWeight': '500' }}>{getBreakeven()}</span>
     </Form.Item>
    </Col> */}

        {/* <Col>
     <Form.Item
      label="% Risk">
      <span style={{ 'fontWeight': '500',
       'color': 'red' }}>{riskPercentage}%</span>
     </Form.Item>
    </Col> */}
        <Col>
          <Form.Item style={{ alignItems: "centre" }} label="Planned R:R">
            <Tag
              color="green"
              style={{ fontWeight: "bold",
                marginLeft: "20px" }}>{`1: ${
                isNaN((reward / risk).toFixed(2))
                  ? ""
                  : (reward / risk).toFixed(2)
              }`}</Tag>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Risk/Lot">
            <span style={{ fontWeight: "500",
              color: "red" }}>
              {risk / (parseFloat(form.getFieldValue("quantity")) / 25)}
            </span>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OptionsZoneForm;
